import { useState } from 'react';
import { dateTimeFormat, getImageURL } from 'functions/functions';
import CheckPermission from 'components/checkPermission/checkPermission';
import useApi from 'hooks/useApi';
import Row from 'layouts/row/row';
import CustomModal  from 'components/modal/modal';
import Button       from 'components/buttons/button';
import styles       from './visitorDetailsModal.module.scss';
import UserPlaceholder from 'assets/images/user-placeholder.png'
import { showErrorToast, showSuccessToast } from 'functions/toasts';


// Body Content for the Modal
const Body = ({selectedVisitor}) => {

    console.log(selectedVisitor)

    return (
        <>
            <div className={styles.detailsHead}>
                <div className={styles.left}>
                    <img src={selectedVisitor.photo_url ? getImageURL(selectedVisitor.photo_url) : UserPlaceholder} />
                </div>
                <div className={styles.right}>
                    <h3>{selectedVisitor.visitor_name}</h3>
                    <div className={styles.infoContainer}>
                        <div className={styles.signInInfoBox}>
                            <h4>SIGNED IN</h4>
                            <p>{dateTimeFormat(selectedVisitor.sign_in_time, "MMM Do YYYY, h:mm a")}</p>
                        </div>
                        <div className={styles.signInInfoBox}>
                            <h4>SIGN IN METHOD</h4>
                            <p>{selectedVisitor.manual_sign_in === 1 ? 'Web Portal' : 'iPad'}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.detailsBody}>
                {selectedVisitor.tenant_name && 
                    <div className={styles.infoItem}>
                        <div className={styles.infoTitle}>COMPANY VISITING</div>
                        <div className={styles.infoContent}>{selectedVisitor.tenant_name}</div>
                    </div> }
                {selectedVisitor.employee_id && 
                <div className={styles.infoItem}>
                    <div className={styles.infoTitle}>{selectedVisitor.form_data.employees.label}</div>
                    <div className={styles.infoContent}>{selectedVisitor.employee.name}</div>
                </div> }
                {selectedVisitor.form_data.fields.map((fields) => {
                    return(
                        <div className={styles.infoItem}>
                            <div className={styles.infoTitle}>{fields.name}</div>
                            <div className={styles.infoContent}>{fields.value || '-'}</div>
                        </div>
                    )
                })}
                
            </div>
        </>
    )
}

// Footer Content for the Modal
const Footer = ({selectedVisitor, signOutVisitor}) => {
    return (
        <div className={styles.footerContainer}>
            <CheckPermission permission="visitors_sign_out">
                {!selectedVisitor.sign_out_time && <Button text="Sign Out" backgroundColor="#FD4848" onClick={signOutVisitor} /> }
            </CheckPermission>
            
        </div>
    )
}

/**
 * Visitor Details Modal
 * 
 * @param {Boolean} isModalOpen     - Boolean value to show or hide Visitor Details Modal
 * @param {Function} closeModal     - Function to close the modal  
 */
const VisitorDetailsModal = ({isModalOpen, closeModal, selectedVisitor, getCurrentVisitorsData}) => {
    const [showConfirmLogout, setShowConfirmLogout] = useState(false);

    const {post} = useApi();

    const signOutVisitor = () => {
        setShowConfirmLogout(true);
    }

    const signOut = async () => {
        try {
            await post(`visitor/${selectedVisitor.visitor_id}/sign-out`);
            await getCurrentVisitorsData();
            showSuccessToast('Visitor Signed Out Successfully');
            setShowConfirmLogout(false);
            closeModal();
        } catch(err) {
            showErrorToast('Something Went Wrong');
        }
    }
    
    return (
        <>
            <CustomModal isModalOpen={isModalOpen} closeModal={closeModal} width="50%" minHeight="55%" maxHeight="70%">
                {{ 
                    header : <h2>Visitor Details</h2>,
                    body : <Body selectedVisitor={selectedVisitor} />,
                    footer : <Footer selectedVisitor={selectedVisitor} signOutVisitor={signOutVisitor} />
                }}
            </CustomModal>
            <ConfirmSignOutModal isModalOpen={showConfirmLogout} closeModal={() => setShowConfirmLogout(false)} signOut={signOut} />
        </>
    )
}


const ConfirmSignOutModal = ({isModalOpen, closeModal, signOut}) => {
    const [loading, setLoading] = useState(false);

    const signOutVisitor = async () => {
        setLoading(true);
        await signOut();
        setTimeout(() => {
            setLoading(false);
        }, 500);
        
    }

    const SignOutBody = () => {
        return (
            <>
                <p>Are you sure you want to sign out the visitor ?</p>
            </>
        )
    }

    const SignOutFooter = () => {
        return (
            <Row gap="1rem" justify="flex-end">
                <Button text="Yes, Sign Out" disabled={loading} loading={loading} loadingText="Signing Out..." backgroundColor="#FD4848" onClick={signOutVisitor} />
            </Row>
        )
    }

    return (
        <CustomModal isModalOpen={isModalOpen} closeModal={closeModal} width="35%" minHeight="30%">
            {{ 
                header : <h2>Confirm Sign Out</h2>,
                body : <SignOutBody />,
                footer : <SignOutFooter />
             }}
        </CustomModal>
    )
}

export default VisitorDetailsModal;