import { useState, useEffect } from "react";
import useApi from "hooks/useApi";
import { showErrorToast, showSuccessToast } from "functions/toasts";
import { useAuth } from "contexts/authContext";
import useTenantFunctions from "functions/useTenantFunctions";
import { dateTimeFormat, getImageURL } from "functions/functions";
import UserPlaceholder from 'assets/images/user-placeholder.png';

const useDeliveryReports = () => {

    const {currentBuilding, user} = useAuth();

    const [loading, setLoading] = useState(true);
    const [dataLoading, setDataLoading] = useState(false);
    const [filterLoading, setFilterLoading] = useState(false);
    const [visitorName, setVisitorName] = useState('');
    const [data, setData] = useState([]);
    const [tenants, setTenants] = useState([]);
    const [sites, setSites] = useState([]);
    const [selectedSite, setSelectedSite] = useState({
            label : currentBuilding.attributes.name,
            value : currentBuilding.attributes.building_id
        });
    const [selectedTenant, setSelectedTenant] = useState({
            label : 'All Tenants',
            value : 'all'
    });
    const [filteredData, setFilteredData] = useState([]);
    const [state, setState] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
    ]);

    
    const {getAllTenants} = useTenantFunctions();

    console.log(currentBuilding)

    const {get} = useApi();

    useEffect(() => {
        fetchData();
    },[currentBuilding]);

    const fetchData = async () => {
        setLoading(true);

        try {
            await Promise.all([setSiteOptions()]);
            await setTenantOptions(selectedSite.value);
            await fetchDeliveries();
        } catch(err) {

        } finally {
            setLoading(false);
        }

    };

    // Function to fetch visitors data
    const fetchDeliveries = async () => {
        setDataLoading(true);
        try{
            const response = await get(`buildings/${selectedSite.value || currentBuilding.attributes.building_id}/deliveries-collected?start_date=${dateTimeFormat(state[0].startDate, "YYYY-MM-DD")}&end_date=${dateTimeFormat(state[0].endDate, "YYYY-MM-DD")}`);
            console.log(response)
            setData(response.data.data);
            

            const filteredResult = response.data.data.filter((item) => {
                if(selectedTenant.value === 'all') return item;
                if(selectedTenant.value === item.tenant.tenant_id) return item;
            })

            setFilteredData(filteredResult);

            
            setDataLoading(false);
        } catch(err) {
            console.log(err);
            showErrorToast('Something Went Wrong');
        }
    }

    // Function to get tenants
    const setTenantOptions = async (siteId) => {
        setFilterLoading(true);
        try {
            const response = await getAllTenants(siteId);
            console.log('Tenants',response)
            let tenantOptions = [{
                label : 'All Tenants',
                value : 'all'
            }];
            response.forEach(tenant => {
                if(tenant.attributes.is_active === 1) {
                    tenantOptions.push({
                        ...tenant.attributes,
                        label : tenant.attributes.name,
                        value : tenant.attributes.tenant_id,
                    })
                }
            });

            console.log(tenantOptions)
            setTenants(tenantOptions);
            setSelectedTenant({
                label : 'All Tenants',
                value : 'all'
            })
        } catch(err) {
            showErrorToast('Something Went Wrong')
        }

        setFilterLoading(false)
    }

    // Function to set site options
    const setSiteOptions = async () => {
        console.log(user.approved_buildings)
        let siteOptions = [];
        user.approved_buildings.buildings.forEach((building) => {
            siteOptions.push({
                label : building.attributes.name,
                value : building.attributes.building_id
            })
        });

        setSites(siteOptions);
    }

    // Colums for the visitor reports table
    const columns = [
        // {
        //     name : "Visitor Name", 
        //     width: '250px', 
        //     cell : (row) => (
        //     <div style={{ display : 'flex', justifyContent : 'flex-start', alignItems : 'center', gap : '1rem'}}>
        //         <img src={row.photo ? getImageURL(row.photo) : UserPlaceholder} style={{ borderRadius : '50%', width : '35px' }} />
        //         <p style={{ fontWeight : '500' }}>{row.visitor_name}</p>
        //     </div>
        //     )
        // },
        {
            name: 'Delivery Id',
            selector: row => row.delivery_id,
        },
        {
            name: 'Tenant',
            selector: row => row.tenant.name,
        },
        {
            name: 'Employee',
            selector: row => row.employee ? row.employee.name : '-',
        },
        {
            name: 'Arrival Time',
            selector: row => dateTimeFormat(row.arrival_time),
        },
        {
            name: 'Collection Time',
            selector: row => dateTimeFormat(row.collection_time),
        },
    ];

    // Columns of CSV File
    const header = [
        {label : "Delivery Id",     key : "delivery_id"},
        {label : "Tenant",          key : 'tenant.name'},
        {label : "Employee",        key : 'employee.name'},
        {label : "Arrival Time",    key : "arrival_time"},
        {label : "Collection Time",   key : "collection_time"},
      ];

    // Apply Filters
    const applyFilters = () => {
        fetchDeliveries();
    }

    // When a site is changed
    const onSiteChange = (e) => {
        setTenantOptions(e.value)
    }

    return {
        loading, setLoading,
        dataLoading, setDataLoading,
        filterLoading, setFilterLoading,
        data, setData,
        tenants, setTenants,
        selectedTenant, setSelectedTenant,
        sites, setSites,
        selectedSite, setSelectedSite,
        filteredData, setFilteredData,
        columns,
        applyFilters,
        onSiteChange,
        state, setState,
        visitorName, setVisitorName,
        header
    }
}

export default useDeliveryReports;