import { useState, useEffect }          from 'react';
import { showErrorToast }               from 'functions/toasts';
import useApi                           from 'hooks/useApi';
import { useAuth }                      from 'contexts/authContext';
import UserPlaceholder                  from 'assets/images/user-placeholder.png'
import { dateTimeFormat, timeFormat }   from 'functions/functions';


// Custom hook for the pre-registered visitors
const usePreRegVisitors = () => {

    const [modalOpen, setModalOpen] = useState(false);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visitorName, setVisitorName] = useState('');
    const [tenantOptions, setTenantOptions] = useState([]);
    const [selectedTenant, setSelectedTenant] = useState('');
    const [selectedPreRegUser, setSelectedPreRegUser] = useState('');

    const {get} = useApi();
    const {currentBuilding} = useAuth();

    useEffect(() => {
        fetchData()
        
    },[currentBuilding]);

    const fetchData = async () => {
        setLoading(true);
        try {
            await Promise.all([fetchPreRegisteredVisitors(), fetchTenantsOptions()]);
            setLoading(false);
        } catch(err) {

        }
    }

    const fetchPreRegisteredVisitors = async () => {

        try {
            const response = await get(`buildings/${currentBuilding.attributes.building_id}/pre-registered-visitors`);
            setFilteredData(response.data.data);
            setData(response.data.data);
        } catch(err) {
            showErrorToast('Something Went Wrong');
        }
    }

    const fetchTenantsOptions = async () => {
        try {
            const response = await get(`buildings/${currentBuilding.attributes.building_id}/tenants`);
            const data = response.data.data;

            let tenantArray = [{
                label : 'All Tenants',
                value : 'all'
            }];

            data.forEach(tenant => {
                if(tenant.attributes.is_active === 1) {
                    tenantArray.push({
                        ...tenant.attributes,
                        label : tenant.attributes.name,
                        value : tenant.attributes.tenant_id,
                    })
                }
            });

            setTenantOptions(tenantArray);

            setSelectedTenant({
                label : 'All Tenants',
                value : 'all'
            })

        } catch(err) {
            showErrorToast('Something Went Wrong');
        }
    }


    const onTenantChange = (e) => {
        console.log(e);

        let filteredData;

        if(e.value !== 'all') {
            filteredData = data.filter((data) => {
                return data.tenant_id === e.value
            })
        }

        if(e.value === 'all') filteredData = data;

        setFilteredData(filteredData);
    }

    const columns = [
        {
            name : "Visitor Name", 
            width: '250px', 
            cell : (row) => (
            <div style={{ display : 'flex', justifyContent : 'flex-start', alignItems : 'center', gap : '1rem'}} onClick={() => onPreRegVisitorClick(row)}>
                <img src={row.photo || UserPlaceholder} style={{ borderRadius : '50%', width : '35px' }} />
                <p style={{ fontWeight : '500' }}>{row.visitor_name}</p>
            </div>
            )
        },
        {
            name: 'Tenant',
            cell : (row) => (
                <p onClick={() => onPreRegVisitorClick(row)}>{row.tenant_name || '-'}</p>
            )
        },
        {
            name: 'Arrival Date & Time',
            cell : (row) => (
                <p onClick={() => onPreRegVisitorClick(row)}>{dateTimeFormat(row.arrival_date, "MMM Do YYYY")}{row.arrival_time ? `, ${timeFormat(row.arrival_time)}` : ''}</p>
            )
        },
    ];

    // Columns of CSV File
    const header = [
        {label : "Visitor Name",    key : "visitor_name"},
        {label : "Tenant",          key : "tenant_name"},
        {label : "Arrival Date",    key : "arrival_date"},
        {label : "Arrival Time",    key : "arrival_time"},
      ];
    
    const statusOptions = [
        { value: 'All Status', label: 'All Status' },
        { value: 'Active', label: 'Active' },
        { value: 'Inactive', label: 'Inactive' },
    ];

    const rowClick = () => {
        setModalOpen(true);
    }

    const onPreRegVisitorClick = (row) => {
        setSelectedPreRegUser(row);
        setModalOpen(true);
    }

    const closeModal = () => {
        setModalOpen(false);
    }

    return {
        modalOpen,
        setModalOpen,
        columns,
        data, filteredData,
        tenantOptions,
        statusOptions,
        rowClick,
        closeModal,
        onTenantChange,
        selectedTenant,
        visitorName, setVisitorName,
        header,
        onPreRegVisitorClick,
        fetchData,
        selectedPreRegUser, setSelectedPreRegUser,
        loading, setLoading,
    }
}

export default usePreRegVisitors