import React, { useState }                from 'react'
import styles               from './header.module.scss';
import LogoTheme            from 'assets/images/visitorpad logo.png';
import Evacuate             from 'assets/icons/evacuate.svg';
import Dropdown             from 'components/dropdown/dropdown';
import Lottie               from "lottie-react";
import { IconContext }      from "react-icons";
import { BiMenuAltLeft}     from 'react-icons/bi';
import { FaUserCircle }     from 'react-icons/fa';
import { IoIosArrowDown, IoIosArrowUp}    from 'react-icons/io';
import LogoutModal from 'components/modal/logoutModal/logoutModal';
import { useAuth } from 'contexts/authContext';
import Alert from 'assets/animations/alert2.json';


const SiteDropdownHead = ({dropdownStatus, siteName}) => {
    return (
        <div className={styles.building}>
            <p>{siteName}</p>
            <IconContext.Provider value={{ size : '1.5rem'}}>
                {dropdownStatus ? <IoIosArrowUp /> : <IoIosArrowDown /> }
            </IconContext.Provider>
        </div>
    )
}

const ProfileDropdownHead = ({dropdownStatus, userName}) => {
    return (
        <div className={styles.user}>
            <IconContext.Provider value={{ color:'037093', size : '3rem'}}>
                <FaUserCircle />
            </IconContext.Provider>
            <p>{userName}</p>
            <IconContext.Provider value={{ className : styles['arrow-down'] }}>
                <IoIosArrowDown />
            </IconContext.Provider>
        </div>
    )
} 

/**
 * Main Header Component
*/

function Header() {

    const [showDropDown, setShowDropDown] = useState(false);
    const [logoutModalStatus, setLogoutModalStatus] = useState(false);

    const {user, currentBuilding, setCurrentBuilding, setBuilding} =  useAuth();

    const onSiteClick = (building) => {
        console.log(building)
        setBuilding(building); 
    }

    const closeLogoutModal = () => {
        setLogoutModalStatus(false)
    }


  return (
    <>
    <div className={styles.header}>
        <div className={styles.left}>
            {/* <IconContext.Provider value={{ color: "#002547", size : '3rem' }}>
                <BiMenuAltLeft />
            </IconContext.Provider> */}
            <img src={LogoTheme} className={styles.logo} />
        </div>
        <div className={styles.right}>
            {/* <div className={styles.evacuate}>
                <Lottie animationData={Alert} loop={true} style={{ width : 30, height : 30 }} />
                <p>Evacuate</p>
            </div> */}
            <Dropdown id="siteDropdown">
                <SiteDropdownHead siteName={currentBuilding?.attributes?.name} />
                <div className={styles.buildingListContainer}>
                    <ul>
                        {user.approved_buildings.buildings.sort((a,b) => a.attributes.name < b.attributes.name ? -1 : 0).map((building) => {
                            return (
                                <li className={styles.current} onClick={() => onSiteClick(building)}>
                                    <p>{building?.attributes?.name}</p>
                                    {building?.attributes?.building_id === currentBuilding?.attributes?.building_id && <span className={styles.current}>CURRENT</span> }
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </Dropdown>
            <Dropdown id="profileDropdown">
                <ProfileDropdownHead userName={user.name} />
                <div className={styles.buildingListContainer}>
                    <ul>
                        <li onClick={() => setLogoutModalStatus(true)}>Logout</li>
                    </ul>
                </div>
            </Dropdown>
            {/* <div className={styles.user}>
                <IconContext.Provider value={{ color:'037093', size : '3rem'}}>
                    <FaUserCircle />
                </IconContext.Provider>
                <p>{user.name}</p>
                <IconContext.Provider value={{ className : styles['arrow-down'] }}>
                    <IoIosArrowDown />
                </IconContext.Provider>
            </div> */}
        </div>
    </div>
    <LogoutModal isModalOpen={logoutModalStatus} closeModal={closeLogoutModal} />
    </>
  )
}

export default Header