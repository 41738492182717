import { useContext } from "react";
import { useNavigate, useLocation }                         from "react-router-dom";
import { MdOutlineArrowBackIosNew, MdKeyboardArrowRight }   from 'react-icons/md';
import BreadCrumbContext from "contexts/breadcrumbContext";
import DashboardLayout                                      from "layouts/dashboardLayout/dashboard.jsx"
import Icon                                                 from "components/icon/icon"; 
import Settings                                             from 'assets/images/settings.png';
import ManageTenants                                        from 'assets/images/tenants green.png';
import styles                                               from './site.module.scss';
import CheckPermission from "components/checkPermission/checkPermission";

const SiteScreen = () => {
    
    const navigate = useNavigate();
    const location = useLocation();

    const {
        managedSiteName, setManagedSiteName,
        managedSiteId, setManagedSiteId
    } = useContext(BreadCrumbContext);

    return (
        <DashboardLayout>
            <div className="gb-head">
                <h2>{managedSiteName}</h2>
            </div>
            <div className="gb-head-navigation">
                <div className="gb-head-navigation__back" onClick={() => navigate('/manage/sites')}>
                    <Icon icon={<MdOutlineArrowBackIosNew />} size="1.5rem" color="#131313" />
                    <p>Back</p>
                </div>
                <div className="gb-head-navigation__breadcrumbs">
                    <p className="gb-head-navigation__breadcrumbs--link" onClick={() => navigate('/manage/sites')}>Sites</p>
                    <Icon icon={<MdKeyboardArrowRight />} size="1.5rem" color="#131313" />
                    <p className="gb-head-navigation__breadcrumbs--current">{managedSiteName}</p>
                </div>
            </div>
            <div className={styles.optionsContainer}>
                <div className={styles.option} onClick={() => navigate(`/manage/sites/${managedSiteId}/details`)}>
                    <img src={Settings} />
                    <p>Site Settings</p>
                </div>
                <CheckPermission permission="tenants_view">
                    <div className={styles.option} onClick={() => navigate(`/manage/sites/${managedSiteId}/tenants`)}>
                        <img src={ManageTenants} />
                        <p>Manage Tenants</p>
                    </div>
                </CheckPermission>
            </div>
        </DashboardLayout>
    )
}

export default SiteScreen