import { useState, useEffect } from "react";
import useApi from "hooks/useApi";
import { showErrorToast, showSuccessToast } from "functions/toasts";
import { useAuth } from "contexts/authContext";
import useTenantFunctions from "functions/useTenantFunctions";
import { dateTimeFormat, getImageURL } from "functions/functions";
import UserPlaceholder from 'assets/images/user-placeholder.png';
import moment from "moment";

const useVisitorReports = () => {

    const {currentBuilding, user} = useAuth();

    const [loading, setLoading] = useState(true);
    const [dataLoading, setDataLoading] = useState(false);
    const [filterLoading, setFilterLoading] = useState(false);
    const [visitorName, setVisitorName] = useState('');
    const [data, setData] = useState([]);
    const [tenants, setTenants] = useState([]);
    const [sites, setSites] = useState([]);
    const [selectedSite, setSelectedSite] = useState({
            label : currentBuilding.attributes.name,
            value : currentBuilding.attributes.building_id
        });
    const [selectedTenant, setSelectedTenant] = useState({
            label : 'All Tenants',
            value : 'all'
    });
    const [filteredData, setFilteredData] = useState([]);
    const [state, setState] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
    ]);
    const [selectedUnit, setSelectedUnit] = useState('week');
    const [lineChartData, setLineChartData] = useState([]);
    const [chartLoading, setChartLoading] = useState(false)

    
    const {getAllTenants} = useTenantFunctions();

    console.log(currentBuilding)

    const {get} = useApi();

    useEffect(() => {
        fetchData();
    },[currentBuilding]);

    const fetchData = async () => {
        setLoading(true);

        try {
            await Promise.all([setSiteOptions()]);
            await setTenantOptions(selectedSite.value);
            await fetchVisitors();
            const lineData = await getLineChartData(selectedUnit); 
            console.log(lineData)
            setLineChartData(lineData);

        } catch(err) {

        } finally {
            setLoading(false);
        }

    };

    const onChartUnitChange = async (unit) => {
        const lineData = await getLineChartData(unit);
        console.log(lineData);
        setLineChartData(lineData);
        setSelectedUnit(unit);
    }

    // Function to fetch visitors data
    const fetchVisitors = async () => {
        setDataLoading(true);
        try{
            const response = await get(`buildings/${selectedSite.value || currentBuilding.attributes.building_id}/visitors?data=signed_out&start_date=${dateTimeFormat(state[0].startDate, "YYYY-MM-DD")}&end_date=${dateTimeFormat(state[0].endDate, "YYYY-MM-DD")}`);
            setData(response.data.data);
            
            // Filtering Data based on selected tenant
            const filteredResult = response.data.data.filter((item) => {
                if(selectedTenant.value === 'all') return item;
                if(selectedTenant.value === item.tenant_id) return item;
            })

            setFilteredData(filteredResult);
            

            setDataLoading(false);
            return response.data.data;
        } catch(err) {
            console.log(err);
            showErrorToast('Something Went Wrong');
        }
    }

    // Function to get tenants
    const setTenantOptions = async (siteId) => {
        setFilterLoading(true);
        try {
            const response = await getAllTenants(siteId);
            console.log('Tenants',response)
            let tenantOptions = [{
                label : 'All Tenants',
                value : 'all'
            }];
            response.forEach(tenant => {
                if(tenant.attributes.is_active === 1) {
                    tenantOptions.push({
                        ...tenant.attributes,
                        label : tenant.attributes.name,
                        value : tenant.attributes.tenant_id,
                    })
                }
            });

            console.log(tenantOptions)
            setTenants(tenantOptions);
            setSelectedTenant({
                label : 'All Tenants',
                value : 'all'
            })
        } catch(err) {
            showErrorToast('Something Went Wrong')
        }

        setFilterLoading(false)
    }

    // Function to set site options
    const setSiteOptions = async () => {
        console.log(user.approved_buildings)
        let siteOptions = [];
        user.approved_buildings.buildings.forEach((building) => {
            siteOptions.push({
                label : building.attributes.name,
                value : building.attributes.building_id
            })
        });

        setSites(siteOptions);
    }

    // Colums for the visitor reports table
    const columns = [
        {
            name : "Visitor Name", 
            width: '250px', 
            cell : (row) => (
            <div style={{ display : 'flex', justifyContent : 'flex-start', alignItems : 'center', gap : '1rem'}}>
                <img src={row.photo ? getImageURL(row.photo) : UserPlaceholder} style={{ borderRadius : '50%', width : '35px' }} />
                <p style={{ fontWeight : '500' }}>{row.visitor_name}</p>
            </div>
            )
        },
        {
            name: 'Tenant',
            selector: row => row.tenant_name,
        },
        {
            name: 'Sign In Time',
            selector: row => dateTimeFormat(row.sign_in_time),
        },
        {
            name: 'Sign Out Time',
            selector: row => dateTimeFormat(row.sign_out_time),
        },
    ];


    const getLineChartData = async (unit) => {
        setChartLoading(true)
        const response = await get(`buildings/${selectedSite.value || currentBuilding.attributes.building_id}/visitors?data=signed_out`);
        console.log(response)
        const data = response.data.data;

        const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        const startOfWeek = moment().startOf('isoWeek');
        const endOfWeek = moment().endOf('isoWeek');
        const daysDataSignedIn = Array(7).fill(0);                          // Array to track days signed in data
        const monthsDataSignedIn = Array(moment().month() + 1).fill(0);     // Array to track monthly signed in data

        console.log(startOfWeek, endOfWeek);

        data.forEach((item) => {
            const signInTime = moment(item.sign_in_time, "YYYY-MM-DD hh:mm a");
            console.log(item.sign_in_time);
            if(signInTime.isBetween(startOfWeek, endOfWeek, 'day', '[]')) {
                
                const dayIndex = daysOfWeek.indexOf(signInTime.format('dddd'));
                daysDataSignedIn[dayIndex]++;
            }

            const monthIndex = signInTime.month();
            monthsDataSignedIn[monthIndex]++;
        })

        console.log('DAYS DATA', daysDataSignedIn);
        console.log('MONTHS DATA', monthsDataSignedIn.length);

        if(unit == "this-month") {
            const startOfMonth = moment().startOf('month');
            const weeksDataSignedIn = Array(startOfMonth.clone().endOf('month').isoWeek() - startOfMonth.isoWeek() + 1).fill(0);

            data.forEach((item) => {
                const signInTime = moment(item.sign_in_time, "YYYY-MM-DD hh:mm a");
                if(signInTime.isBetween(startOfMonth, startOfMonth.clone().endOf('month'), 'day', '[]')) {
                    const weekNumber = signInTime.isoWeek() - startOfMonth.isoWeek();
                    weeksDataSignedIn[weekNumber]++;
                }
            })
            setChartLoading(true)
            return {
                labels: Array(weeksDataSignedIn.length).fill(0).map((_, index) => `Week ${index + 1}`),
                datasets: [
                  {
                    label: 'Visitors',
                    data: weeksDataSignedIn,
                    backgroundColor: '#00B9AE',
                  },
                //   {
                //     label: 'Returned',
                //     data: weeksDataReturned,
                //     backgroundColor: '#037171',
                //   },
                ],
              };
        }
        setChartLoading(true)
        return {
            labels: unit === 'week' ? daysOfWeek : moment.monthsShort(), // Use short month names
            datasets: [
              {
                label: 'Visitors',
                data: unit === 'week' ? daysDataSignedIn : monthsDataSignedIn,
                backgroundColor: '#00B9AE',
              },
            //   {
            //     label: 'Returned',
            //     data: unit === 'week' ? daysDataReturned : monthsDataReturned,
            //     backgroundColor: '#037171',
            //   },
            ],
          };
        
    }

    // Columns of CSV File
    const header = [
        {label : "Visitor Name",    key : "visitor_name"},
        {label : "Tenant",          key : "tenant_name"},
        {label : "Sign In TIme",    key : "sign_in_time"},
        {label : "Sign Out Time",   key : "sign_out_time"},
      ];

    // Apply Filters
    const applyFilters = () => {
        fetchVisitors();
    }

    // When a site is changed
    const onSiteChange = (e) => {
        setTenantOptions(e.value)
    }

    return {
        loading, setLoading,
        dataLoading, setDataLoading,
        filterLoading, setFilterLoading,
        data, setData,
        tenants, setTenants,
        selectedTenant, setSelectedTenant,
        sites, setSites,
        selectedSite, setSelectedSite,
        filteredData, setFilteredData,
        columns,
        applyFilters,
        onSiteChange,
        state, setState,
        visitorName, setVisitorName,
        selectedUnit, setSelectedUnit,
        header,
        lineChartData,
        onChartUnitChange,
        chartLoading, setChartLoading
    }
}

export default useVisitorReports;