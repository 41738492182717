import { useState }         from "react"
import { PhotoProvider, PhotoView } from 'react-photo-view';
import DashboardLayout      from "layouts/dashboardLayout/dashboard.jsx"
import SubMenuLayout        from "layouts/subMenuLayout/subMenuLayout"
import PageHeader           from "components/pageHeader/pageHeader"
import RSwitch              from "components/switch/switch"
import Parcel               from 'assets/images/parcel-blue.png';
import Mobile               from 'assets/images/mobile-blue.png';
import Icon                 from "components/icon/icon";
import { IoEyeOutline }     from "react-icons/io5";
import { FaCheck }          from "react-icons/fa6";
import TextInput            from "components/inputs/textInput/textInput"
import TimePick             from "components/inputs/timePick/timePick"
import Button               from "components/buttons/button"
import useSettings          from "./useSettings"
import Template01           from "assets/images/badges/template-01.png";
import Template02           from "assets/images/badges/template-02.png";
import Template03           from "assets/images/badges/template-03.png";

import 'react-photo-view/dist/react-photo-view.css';
import styles               from './settings.module.scss';
import CheckPermission from "components/checkPermission/checkPermission";

const SiteSettingsScreen = () => {

    const  {
        deliveriesStatus, setDeliveriesStatus,
        buddyAppStatus, setBuddyAppStatus,
        signOutStatus, setSignOutStatus,
        signOutTime, setSignOutTime,
        badgePrintingStatus, setBadgePrintingStatus,
        loading, setLoading,
        error, setError,
        errorMessage, setErrorMessage,
        selectedBadge, setSelectedBadge,
        saveChanges
    } = useSettings();

    return (
        <DashboardLayout>
            
            <SubMenuLayout subMenuType="site" currentMenu="Settings">
                <p className="gb_title">Settings</p>
                <p className="gb_subtitle">Manage your site settings like features, policies and so on.</p>
                {!loading &&
                <div className={styles.featuresContainer}>
                    <div className={styles.heading}>
                        <h3 className={styles.settingHead}>Features</h3>
                        <div class={styles.horizontalLine}></div>
                    </div>
                    <div className={styles.featuresList}>
                        <div className={styles.feature}>
                            <img src={Parcel} />
                            <p className={styles.head}>Deliveries</p>
                            <p className={styles.info}>This feature allow you to manage deliveries of the site.</p>
                            <div className={styles.switch}>
                                <RSwitch status={deliveriesStatus} onChange={() => setDeliveriesStatus(!deliveriesStatus)} />
                                <p style={{ color : deliveriesStatus ? '#42BA78' : '#a5a5a5' }}>{deliveriesStatus ? 'Active' : 'Inactive'}</p>
                            </div>
                        </div>
                        {/* <div className={styles.feature}>
                            <img src={Mobile} />
                            <p className={styles.head}>Buddy App</p>
                            <p className={styles.info}>Buddy App allow the tenants to manage their visitors and deliveries.</p>
                            <div className={styles.switch}>
                                <RSwitch status={buddyAppStatus} disabled={true} onChange={() => setBuddyAppStatus(!buddyAppStatus)} />
                                <p style={{ color : buddyAppStatus ? '#42BA78' : '#a5a5a5' }}>{buddyAppStatus ? 'Active' : 'Inactive'}</p>
                            </div>
                        </div> */}
                    </div>
                </div> }

                {!loading &&
                <div className={styles.signOutPolicyContainer}>
                    <div className={styles.heading}>
                        <h3 className={styles.settingHead}>Sign Out Policy</h3>
                        <div class={styles.horizontalLine}></div>
                    </div>
                    <div className={styles.form}>
                        <RSwitch label="Sign Out all visitors automatically at the end of the Day" status={signOutStatus} onChange={() => setSignOutStatus(!signOutStatus)} />
                        {signOutStatus ? <TimePick isError={error.time} errorMessage={errorMessage.time} label="Default Sign Out Time (in 24hr format)" value={signOutTime} onChange={(e) => setSignOutTime(e.target.value)} /> : '' }
                    </div>
                </div> }


                {!loading &&
                <div className={styles.badgePrintingContainer}>
                    <div className={styles.heading}>
                        <h3 className={styles.settingHead}>Badge Printing</h3>
                        <div class={styles.horizontalLine}></div>
                    </div>
                    <div className={styles.form}>
                        <RSwitch label="Print badges when visitor sign in." status={badgePrintingStatus} onChange={() => setBadgePrintingStatus(!badgePrintingStatus)} />
                    </div>
                    <div className="mt-3">
                        <p>Select Badge Template</p>
                        <div className={styles.templatesContainer}>
                            <div className={styles.tempate}>
                                <PhotoProvider>
                                    <div className={styles.templateImage}>
                                        <img src={Template01} alt="Template 01" />
                                        <CheckPermission permission="sites_add_edit">
                                            <div class={styles.overlay}></div>
                                        </CheckPermission>
                                        <CheckPermission permission="sites_add_edit">
                                        <div className={styles.buttons}>
                                            <div class={styles.btn}>
                                                <PhotoView key="template-01" src={Template01}>
                                                    <Icon icon={<IoEyeOutline />} color="#fff" size="2.5rem" />
                                                </PhotoView>
                                            </div>
                                            <div class={styles.btn}>
                                                <Icon icon={<FaCheck />} color="#fff" size="2.5rem" onClick={() => setSelectedBadge("template-01")} />
                                            </div>
                                        </div>
                                        </CheckPermission>
                                        {selectedBadge === "template-01" &&
                                        <div className={styles.selected}>
                                            <p>Selected</p>
                                        </div> }
                                    </div>
                                </PhotoProvider>
                                <p>Standard Template with Photo & QR Code</p>
                            </div>
                            {/* <div className={styles.tempate}>
                                <PhotoProvider>
                                    <div className={styles.templateImage}>
                                        <img src={Template02} alt="Template 02" />
                                        <CheckPermission permission="sites_add_edit">
                                            <div class={styles.overlay}></div>
                                        </CheckPermission>
                                        <CheckPermission permission="sites_add_edit">
                                            <div className={styles.buttons}>
                                                <div class={styles.btn}>
                                                    <PhotoView key="template-01" src={Template02}>
                                                        <Icon icon={<IoEyeOutline />} color="#fff" size="2.5rem" />
                                                    </PhotoView>
                                                </div>
                                                <div class={styles.btn}>
                                                    <Icon icon={<FaCheck />} color="#fff" size="2.5rem" onClick={() => setSelectedBadge("template-02")} />
                                                </div>
                                            </div>
                                        </CheckPermission>
                                        {selectedBadge === "template-02" &&
                                        <div className={styles.selected}>
                                            <p>Selected</p>
                                        </div> }
                                    </div>
                                </PhotoProvider>
                                <p>Template with QR Code and without Photo</p>
                            </div>
                            <div className={styles.tempate}>
                                <PhotoProvider>
                                    <div className={styles.templateImage}>
                                        <img src={Template03} alt="Template 03" />
                                        <CheckPermission permission="sites_add_edit">
                                            <div class={styles.overlay}></div>
                                        </CheckPermission>
                                        <CheckPermission permission="sites_add_edit">
                                        <div className={styles.buttons}>
                                            <div class={styles.btn}>
                                                <PhotoView key="template-01" src={Template03}>
                                                    <Icon icon={<IoEyeOutline />} color="#fff" size="2.5rem" />
                                                </PhotoView>
                                            </div>
                                            <div class={styles.btn}>
                                                <Icon icon={<FaCheck />} color="#fff" size="2.5rem" onClick={() => setSelectedBadge("template-03")} />
                                            </div>
                                        </div>
                                        </CheckPermission>
                                        {selectedBadge === "template-03" &&
                                        <div className={styles.selected}>
                                            <p>Selected</p>
                                        </div> }
                                    </div>
                                </PhotoProvider>
                                <p>Standard Template without Photo & QR Code</p>
                            </div> */}
                        </div>
                    </div>
                </div> }

                <div className="mt-5 gb-flex-end">
                    <CheckPermission permission="sites_add_edit">
                        <Button text="Save Changes" onClick={saveChanges} />
                    </CheckPermission>
                </div>
            </SubMenuLayout>
        </DashboardLayout>
    )
}

export default SiteSettingsScreen