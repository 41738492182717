
import styles from './datepicker.module.scss'

const RDatePicker = ({flex, value, placeholder, label, required, disabled, type, onBlur, onChange, onKeyPress, maxWidth, isError, errorMessage, showError = true}) => {
    return (
        <div
            style={{ 
                flex : flex || 1,
                maxWidth : maxWidth || ''
             }} 
            className={styles.inputContainer}
        >
            {label && <label>{label} {required && <span className={styles.required}>*</span> }</label> }
            <input type='date' value={value} onChange={onChange} onBlur={onBlur} className= {isError ? 'error-outline' : ''}  />
            {showError && <p className={`errorMessage ${isError ? 'active' : ''}`}>{errorMessage || ''}&nbsp;</p> }
        </div>
    )
}

export default RDatePicker