import { useNavigate, useLocation }                                      from "react-router-dom";
import { MdOutlineArrowBackIosNew, MdKeyboardArrowRight }   from 'react-icons/md';
import Icon                                                 from "components/icon/icon"

const PageHeader = ({title, breadcrumbsArray}) => {

    const navigate = useNavigate();
    const location = useLocation();

    const onBackBtnClick = () => {
        const currentPathName = location.pathname;

        // Extract the parts of the pathname
        const pathParts = currentPathName.split('/');
        
        let newPath;

        if(pathParts.at(-3) != "tenants") {
            // Remove the last part (templates) from the path
            newPath = pathParts.slice(0, -1).join('/');
        } else {
            newPath = pathParts.slice(0, -2).join('/');
        }

        console.log('New Path',newPath)

        navigate(newPath);
    }

    return (
        <>
            <div className="gb-head">
                <h2>{title}</h2>
            </div>
            <div className="gb-head-navigation">
                <div className="gb-head-navigation__back" onClick={onBackBtnClick}>
                    <Icon icon={<MdOutlineArrowBackIosNew />} size="1.5rem" color="#131313" />
                    <p>Back</p>
                </div>
                <div className="gb-head-navigation__breadcrumbs">
                    {breadcrumbsArray.map((item) => {
                        return (
                            <>
                                <p className={`gb-head-navigation__breadcrumbs--${item.to ? 'link' : 'current'}`} onClick={() => navigate(item.to)}>{item.name}</p>
                                {item.to && <Icon icon={<MdKeyboardArrowRight />} size="1.5rem" color="#131313" /> }
                            </>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default PageHeader