import { showErrorToast, showSuccessToast } from "functions/toasts";
import useApi from "hooks/useApi";
import { useState, useEffect } from "react";
import { useChannel } from "ably/react";
import useTenantFunctions from "functions/useTenantFunctions";
import ParcelUnAssigned from 'assets/images/parcel-unassigned.png';
import { useAuth } from "contexts/authContext";
import { useParams } from "react-router-dom";
import moment from "moment";


const useDashboard = () => {

    const [currentVisitors, setCurrentVisitors]                         = useState([]);
    const [allVisitors, setAllVisitors]                                 = useState([]);
    const [mockCurrentVisitors, setMockCurrentVisitors]                 = useState([]);
    const [preRegVisitors, setPreRegVisitors]                           = useState([]);
    const [selectedPreRegUser, setSelectedPreRegUser]                   = useState('');
    const [signInModalOpen, setSignInModalOpen]                         = useState(false);
    const [visitorModal, setVisitorModal]                               = useState(false);
    const [preRegisterModal, setPreRegisterModal]                       = useState(false);
    const [signOutAllModal, setSignOutAllModal]                         = useState(false);
    const [tenants, setTenants]                                         = useState([]);
    const [selectedTenant, setSelectedTenant]                           = useState('');
    const [selectedVisitor, setSelectedVisitor]                         = useState('')
    const [searchVisitorName, setSearchVisitorName]                     = useState('');
    const [searchPreRegName, setSearchPreRegName]                       = useState('');
    const [newDeliveries, setNewDeliveries]                             = useState([]);
    const [selectedUnAssignedDelivery, setSelectedUnAssignedDelivery]   = useState('');
    const [confirmModalMode, setConfirmModalMode]                       = useState('');
    const [confirmDeliveryModalOpen, setConfirmDeliveryModalOpen]       = useState(false);
    const [loading, setLoading]                                         = useState(false);

    const {get, post} = useApi();
    const {user, currentBuilding} = useAuth();
    const {getAllTenants} = useTenantFunctions();

    const { channel } = useChannel(`public:visitor-sign-in_${currentBuilding.attributes.building_id}`, async (message) => {
        try {
            console.log('visitor signed in');
            await Promise.all([getCurrentVisitorsData(), getAllVisitorsData()]);
            showSuccessToast('New Visitor Signed In');
        } catch(err) {
            console.error(err);
        }
    });
    
    useEffect(() => {
        fetchData();
    },[currentBuilding]);

    const fetchData = async () => {
        setLoading(true);

        try {
            await Promise.all([getCurrentVisitorsData(), getAllVisitorsData(), preRegisteredVisitors(), getNewDeliveries(), setTenantOptions()]);
        } catch(err) {
            console.error(err);
            showErrorToast('Something Went Wrong');
        } finally {
            setLoading(false);
        }
    }

    // Get all the currently signed in visitors of the building
    const getCurrentVisitorsData = async () => {

        try {
            const response = await get(`buildings/${currentBuilding.attributes.building_id}/visitors?data=signed_in`);
            console.log('Current Visitors',response);
            setCurrentVisitors(response.data.data);
            setMockCurrentVisitors(response.data.data);
        } catch(err) {
            console.log(err);
            showErrorToast('Something Went Wrong');
        }
    }


    // Get all the visitors
    const getAllVisitorsData = async () => {

        try {
            const response = await get(`buildings/${currentBuilding.attributes.building_id}/visitors?date=today`);
            console.log('Current Visitors',response);
            setAllVisitors(response.data.data);
            
        } catch(err) {
            console.log(err);
            showErrorToast('Something Went Wrong');
        }
    }

    // Get Current Day's Pre-Registetred Visitors
    const preRegisteredVisitors = async () => {
        try {
            const response = await get(`buildings/${currentBuilding.attributes.building_id}/pre-registered-visitors`);
            console.log('Pre-Reg',response);

            // Filtering data based on date - filter with current days date
            const today = moment().format('YYYY-MM-DD');
            const filteredData = response.data.data.filter((item) => {
                return moment(item.arrival_date).isSame(today, 'day');
            })
            
            setPreRegVisitors(filteredData);
        } catch(err) {
            showErrorToast('Something Went Wrong');
        }
    }

    // Fetch Tenant Options
    const setTenantOptions = async () => {
        try {
            const response = await getAllTenants(currentBuilding.attributes.building_id);
            console.log('Tenants',response)
            let tenantOptions = [];
            response.forEach(tenant => {
                // Add all active tenants to the tenant options array
                if(tenant.attributes.is_active) {
                    tenantOptions.push({
                        ...tenant.attributes,
                        label : tenant.attributes.name,
                        value : tenant.attributes.tenant_id,
                    })
                }
            });

            // Sorting the tenants option alphabetically
            tenantOptions.sort((a,b) => a.label < b.label ? -1 : 0);

            // Adding All Tenanat Option to the beginning of array
            tenantOptions.unshift({
                label : 'All Tenants',
                value : 'all'
            })
            setTenants(tenantOptions);
            setSelectedTenant({
                label : 'All Tenants',
                value : 'all'
            })
        } catch(err) {
            showErrorToast('Something Went Wrong')
        }
    }

    // Get Newly Arrived Deliveries
    const getNewDeliveries = async () => {
        try {
            const response = await get(`buildings/${currentBuilding.attributes.building_id}/unassigned-deliveries`);
            console.log('Unassigned',response);
            setNewDeliveries(response.data.data);
        } catch(err) {
            showErrorToast('Something Went Wrong');
        }
    }

    const columns = [
        {
            name: 'Visitor Name',
            selector: row => row.visitor_name,
        },
        {
            name: 'Arrival Time',
            selector: row => row.arrival_time || '',
        },
    ];

    // Deliveries Columns
    const deliveryColumns = [
        {
            name : "Unassigned Delivery", 
            width: '200px', 
            cell : (row) => (
            <div style={{ display : 'flex', justifyContent : 'flex-start', alignItems : 'center', gap : '0.5rem'}} onClick={()=> onUnAssignedDeliveryClick(row)}>
                <img src={ParcelUnAssigned} style={{ width : '30px' }} />
                <p style={{ fontWeight : '500' }}>UD-{row.un_delivery_id.toString().padStart(3, '0')}</p>
            </div>
            )
        },
        {
            name: 'Arrival Time',
            selector: row => row.arrival_time || '',
        },
    ];


    const onUnAssignedDeliveryClick = (delivery) => {
        setConfirmModalMode('edit');
        setSelectedUnAssignedDelivery(delivery);
        setConfirmDeliveryModalOpen(true);
    }

    // Function to close Confirm Delivery Modal
    const closeModal = () => {
        setConfirmModalMode('');
        setConfirmDeliveryModalOpen(false);
    }
    
    const data = [
        {
            id: 1,
            title: 'Beetlejuice',
            year: '1988',
        },
        {
            id: 2,
            title: 'Ghostbusters',
            year: '1984',
        },
    ]

    const closeSignInModal = () => {
        setSelectedPreRegUser('');
        setSignInModalOpen(false);
    }

    const closeVisitorModal = () => {
        setVisitorModal(false);
    }
    
    const closePreRegisterModal = () => {
        preRegisteredVisitors();
        setPreRegisterModal(false);
    }

    const closeSignOutAllModal = () => {
        setSignOutAllModal(false);
    }

    const onVisitorCardClick = (visitor) => {
        setSelectedVisitor(visitor);
        setVisitorModal(true);
    }

    // Sign Out All Signed In Visitors of the building
    const signOutAll = async () => {
        setLoading(true);

        try {
           await post(`buildings/${currentBuilding.attributes.building_id}/sign-out-all`);
           await getCurrentVisitorsData();
           closeSignOutAllModal();
           showSuccessToast('All Visitors Signed Out Successfully'); 
        } catch(err) {
            showErrorToast('Something Went Wrong');
        }
        setLoading(false);
    }

    const onPreRegVisitorClick = (row) => {
        setSelectedPreRegUser(row);
        setSignInModalOpen(true);
    }

    return {
        getCurrentVisitorsData,
        onVisitorCardClick,
        selectedVisitor, setSelectedVisitor,
        currentVisitors, setCurrentVisitors,
        mockCurrentVisitors, setMockCurrentVisitors,
        preRegVisitors, setPreRegVisitors,
        signInModalOpen, setSignInModalOpen,
        closeSignInModal,
        visitorModal, setVisitorModal,
        closeVisitorModal,
        preRegisterModal, setPreRegisterModal,
        closePreRegisterModal,
        signOutAllModal, setSignOutAllModal,
        tenants, setTenants,
        closeSignOutAllModal,
        selectedPreRegUser,
        signOutAll,
        fetchData,
        selectedTenant, setSelectedTenant,
        columns,
        deliveryColumns,
        onPreRegVisitorClick,
        data,
        searchVisitorName, setSearchVisitorName,
        searchPreRegName, setSearchPreRegName,
        newDeliveries, setNewDeliveries,
        confirmDeliveryModalOpen,
        setConfirmDeliveryModalOpen,
        onUnAssignedDeliveryClick,
        selectedUnAssignedDelivery, setSelectedUnAssignedDelivery,
        confirmModalMode, closeModal,
        allVisitors, setAllVisitors,
        loading, setLoading
    }
}

export default useDashboard;