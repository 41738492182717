import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CustomModal from "../modal";
import Button from "components/buttons/button";
import useApi from "hooks/useApi";
import DataTable from 'react-data-table-component';
import Row from "layouts/row/row";
import axios from "axios";
import { showErrorToast, showSuccessToast } from "functions/toasts";
import { useAuth } from "contexts/authContext";
import { APP_CONFIG } from "constants/config";
import { useEffect, useState } from "react";
import { checkIfEmpty, isValidDateFormat, isValidEmail, isValidTimeFormat } from "functions/functions";
import SelectInput from "components/inputs/selectInput/selectInput";
import styles from './bulkPreRegVisitorModal.module.scss';

const Body = ({importedData, CSVData, fileHasError, error, errorMessages, tenantOptions, selectedTenant, getTenantTemplate, setSelectedTenant, loading}) => {

    const conditionalRowStyles = [
        {
          when: row => row.error === true, // Condition for the row
          style: {
            backgroundColor: '#fff2f2', // Background color if condition is met
          },
        },
    ];

    const columns = [
        {
            name: 'Visitor Name',
            selector: row => row.name,
        },
        {
            name: 'Arrival Date (DD/MM/YYYY)',
            selector: row => row.date,
        },
        {
            name: 'Time (HH:MM, Optional)',
            selector: row => row.time,
        },
        {
            name: 'Invitation Email',
            selector: row => row.inviteEmail,
        },
    ];

    const onTenantSelect = async (e) => {
        setSelectedTenant(e);
        await getTenantTemplate(e.templateId);
    }
    

    return (
        <div>
            {!loading &&
            <>  
                <div className={styles.companySelect}>
                    <SelectInput 
                        placeholder="Select Company"
                        options={tenantOptions}
                        onChange={onTenantSelect}
                        isError={error.tenant}
                        showError={true}
                        errorMessage={errorMessages.tenant} 
                    />
                </div>
                {fileHasError ? <p className="mb-3 warning-alert">The file has one or more errors. Please check the rows with red background.</p> : ''}
                <DataTable
                    columns={columns}
                    fixedHeader={true}
                    fixedHeaderScrollHeight="40vh"
                    conditionalRowStyles={conditionalRowStyles}
                    data={CSVData}
                    dense
		        />
            </> 
            }
                
        </div>
    )
}

const Footer = ({onBulkImportClick, fileHasError, importing}) => {

    return (
        <Row justify="flex-end">
            <Button text="Import Data" disabled={fileHasError || importing} loading={importing} loadingText="Importing Data" onClick={onBulkImportClick} />
        </Row>
    )
}

const BulkPreRegVisitorModal = ({isModalOpen, closeModal, closeParentModal, importedData, file}) => {

    const [CSVData, setCSVData] = useState([]);
    const [fileHasError, setFileHasError]   = useState(false);
    const [loading, setLoading] = useState(true);
    const [importing, setImporting] = useState(false);
    const [selectedTenant, setSelectedTenant] = useState('');
    const [tenantOptions, setTenantOptions] = useState([]); 
    const [signInForm, setSignInForm] = useState([]);
    const [error, setError] = useState('');
    const [errorMessages, setErrorMessages] = useState('')

    const {get, post} = useApi();
    const {currentBuilding} = useAuth();

    const {id} = useParams();
    

    useEffect(() => {
        formatData();
        setTenantsDropDownOption();
    },[importedData]);

    // Fetch all tenants of the site to load in the dropdown
    const fetchTenants = async () => {
        try {
            const response = await get(`buildings/${currentBuilding.attributes.building_id}/tenants`);
            const data = response.data.data;

            return data;
            
        } catch(err) {
            showErrorToast('Something Went Wrong');
        }
    }

    const setTenantsDropDownOption = async () => {
        console.log('here')
        setLoading(true);
        const data = await fetchTenants();
        let tenantsList = [];
        data.forEach((tenant) => {
            tenantsList.push({
                label : tenant.attributes.name,
                value : tenant.attributes.tenant_id,
                templateId : tenant.attributes.template_id
            })
        });

        
        setTenantOptions(tenantsList);

        setLoading(false)
    }

    const formatData = () => {
        let formattedDataArray = [];
        importedData.forEach(data => {
            console.log(data);
            let formattedData = {
                name : data['Name']?.trim(),
                date : data['Date (DD/MM/YYYY)']?.trim(),
                time : data['Time (hh:mm optional)']?.trim(),
                inviteEmail : data['Invite Email']?.trim()
            }

            let errorObj = {
                name : false,
                date : false,
                time : false,
                inviteEmail : false
            }

            if(checkIfEmpty(formattedData.name)) {
                errorObj.name = true;
            };

            if(checkIfEmpty(formattedData.date)) {
                errorObj.date = true;
            }

            if(!isValidDateFormat(formattedData.date)) {
                errorObj.date = true;
            }

            if(formattedData.time) {
                if(!isValidTimeFormat(formattedData.time)) {
                    errorObj.time = true
                }
            }

            if(formattedData.inviteEmail) {
                if(!isValidEmail(formattedData.inviteEmail)) {
                    errorObj.inviteEmail = true;
                }
            }

            const hasError = Object.values(errorObj).some(value => value === true);

            if(hasError) {
                formattedData['error'] = true;
            }

            formattedDataArray.push(formattedData);
        });

        const fileError = formattedDataArray.some(obj => obj.error === true);
        setFileHasError(fileError);
        setCSVData(formattedDataArray);
        console.log(formattedDataArray);
        setLoading(false);
    }

    const getTenantTemplate = async(templateId) => {
        try {
            const res = await get(`form-templates/${templateId}`);
            let formObj = [];
            console.log(res.data.data)
            res.data.data?.form?.fields?.forEach((field) => {
                let obj = {
                    ...field,
                    value : '',
                };

                if(field.type === 'dropdown') {
                    let array = [];
                    field.dropdownOptions.forEach((item) => {
                        array.push({
                            label : item,
                            value : item
                        })
                    });

                    obj['dropdownOptions'] = array;
                }
                formObj.push(obj);
            })

            console.log(formObj);
            // setForm(res.data.data.form);
            setSignInForm({
                ...res.data.data.form,
                fields : formObj
            })
            console.log(res);
            
        } catch(err) {
            console.error(err)
            showErrorToast('Something Went Wromg');
        }
    }

    const onBulkImportClick = async () => {
        
        try {
            setImporting(true)
            const formData = new FormData();

            let errorObj = {
                tenant : false
            }

            let errorMessageObj = {
                tenant : ''
            }

            console.log(signInForm);

            if(!selectedTenant) {
                console.log('error');
                errorObj.tenant = true;
                errorMessageObj.tenant = "Select Company";
            } else {

                formData.append("csv", file);
                formData.append("tenant_id", selectedTenant.value);
                formData.append("form", JSON.stringify(signInForm));

                const config = {
                    headers : {'Content-Type' : 'multipart/form-data'},
                    withCredentials : true
                }
                await axios.post(`${APP_CONFIG.BASE_URL}buildings/${currentBuilding.attributes.building_id}/pre-register/bulk-import`, formData, config);

                closeModal();
                closeParentModal();
                showSuccessToast('Pre-Registration Data Imported Successfully');
            }

            setError(errorObj);
            setErrorMessages(errorMessageObj);

            setImporting(false)

        } catch(err) {
            showErrorToast('Something Went Wrong');
            console.log(err);
            setImporting(false)
        }
    }
    return (
        <CustomModal isModalOpen={isModalOpen} closeModal={closeModal} width="70%" maxHeight="30%" minHeight="30%" >
            {{ 
                header : 'Bulk Import Visitor Pre-Registration',
                body : <Body 
                            importedData={importedData}
                            CSVData={CSVData}
                            loading={loading}
                            getTenantTemplate={getTenantTemplate}
                            tenantOptions={tenantOptions}
                            selectedTenant={selectedTenant} setSelectedTenant={setSelectedTenant}
                            closeParentModal
                            error={error}
                            errorMessages={errorMessages}
                            fileHasError={fileHasError} 
                        />,
                footer : <Footer 
                            onBulkImportClick = {onBulkImportClick}
                            fileHasError={fileHasError}
                            importing={importing}
                        />
            }}
        </CustomModal>
    )
}

export default BulkPreRegVisitorModal