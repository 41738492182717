import { useState, useEffect }         from 'react';
import { useChannel } from 'ably/react';
import { dateTimeFormat }   from 'functions/functions';
import { useAuth }          from 'contexts/authContext';
import Echo from '@ably/laravel-echo';
import ReactPaginate        from 'react-paginate';
import CustomModal          from 'components/modal/modal';
import DashboardLayout      from 'layouts/dashboardLayout/dashboard.jsx'
import styles               from './dashboard.module.scss';
import DataTable            from 'react-data-table-component';
import Card                 from 'components/card/card';
import ConfirmDeliveryModal from 'components/modal/confirmDeliveryModal/confirmDeliveryModal';
import SignedInVisitorIcon  from 'assets/images/sign-in green.png';
import TotalVisitorIcon     from 'assets/images/visitors-active.png';
import DeliveriesIcon       from 'assets/images/deliveries-red.png';
import Button               from 'components/buttons/button';
import axios                from 'axios';
import TableSkeleton        from 'layouts/skeletons/tableSkeleton/tableSkeleton';
import CheckPermission      from 'components/checkPermission/checkPermission';
import Row                  from 'layouts/row/row';
import SignInModal          from 'components/modal/signInModal/signInModal';
import VisitorDetailsModal  from 'components/modal/visitorDetailsModal/visitorDetailsmodal';
import PreRegisterModal     from 'components/modal/preRegisterModal/preRegisterModal';
import TextInput            from 'components/inputs/textInput/textInput';
import Skeleton             from 'react-loading-skeleton';
import SelectInput          from 'components/inputs/selectInput/selectInput';
import useDashboard         from './useDashboard';
// import socket from "socket";

function DashboardScreen() {

  const {
    getCurrentVisitorsData,
    onVisitorCardClick,
    selectedVisitor, setSelectedVisitor,
    currentVisitors, setCurrentVisitors,
    mockCurrentVisitors, setMockCurrentVisitors,
    preRegVisitors, setPreRegVisitors,
    signInModalOpen, setSignInModalOpen,
    closeSignInModal,
    visitorModal, setVisitorModal,
    signOutAllModal, setSignOutAllModal,
    closeSignOutAllModal,
    tenants,
    closeVisitorModal,
    signOutAll,
    fetchData,
    selectedPreRegUser,
    selectedTenant, setSelectedTenant,
    columns, deliveryColumns,
    data,
    preRegisterModal, setPreRegisterModal,
    onPreRegVisitorClick,
    closePreRegisterModal,
    searchVisitorName, setSearchVisitorName,
    searchPreRegName, setSearchPreRegName,
    newDeliveries, setNewDeliveries,
    onUnAssignedDeliveryClick,
    allVisitors, setAllVisitors,
    confirmDeliveryModalOpen, confirmModalMode, closeModal, selectedUnAssignedDelivery,
    loading
  } = useDashboard();

//   socket.on("orderPlaced", (data) => {
//     console.log("order placed:", data);
// })

// useEffect(()=>{
//   socket.emit("userJoin", { userType: "waiter" })
//   },
// []);

  // useEffect(() => {
  //   window.Echo.channel('channel')
  //     .listnen('NewVisitorEvent', (data) => {
  //       console.log('Received message:', data);
  //       // Handle received message data
  //     })
  //     .here((users) => {
  //       console.log('Subscribed to private channel with users:', users);
  //     })
  //     .joining((user) => {
  //         console.log('User joined private channel:', user);
  //     })
  //     .leaving((user) => {
  //         console.log('User left private channel:', user);
  //     });

  //     // Clean up the subscription when component unmounts
  //     return () => {
  //       window.Echo.leave('message');
  //   };
  // },[]);

  const {user} = useAuth();

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);

  const filteredData = mockCurrentVisitors
    .filter((visitor) => visitor.visitor_name.toLowerCase().includes(searchVisitorName.toLowerCase()))
    .filter((visitor) => {
      if(selectedTenant.value === 'all') return visitor;
      else return visitor.tenant_id === selectedTenant.tenant_id });
  
  const totalItems = filteredData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  
  const paginatedData = filteredData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const refreshAccessToken = () => {
    axios.get('http://localhost:8000/sanctum/csrf-cookie').then(response => {

      console.log(response);
      // Now, make a request to refresh the access token
      const headers = {
        'Content-Type': 'application/json',
        'Accept' : 'application/json'
    }

      axios.post('http://localhost:8000/api/refresh-token',  null, {headers, withCredentials : true}).then(response => {
        console.log(response);
          // Update the stored access token with the new one
          // Handle the refreshed token as needed
      });
  });
  }

  return (
    <>
      <DashboardLayout>
        <div className="gb-head">
          <h2>Dashboard</h2>
        </div>
        <div className={styles.statContainer}>
          <div className={styles.statContainer__stat}>
            <div className={styles.statContainer__stat__left}>
              <h3>{String(currentVisitors.length).padStart(2, '0')}</h3>
              <p>Visitors Signed In</p>
            </div>
            <div className={styles.statContainer__stat__right}>
              <div className={styles.statContainer__stat__right_icon} style={{ backgroundColor : '#E3FDEE' }}>
                  <img src={SignedInVisitorIcon} />
              </div>
            </div>
          </div>
          <div className={styles.statContainer__stat}>
            <div className={styles.statContainer__stat__left}>
              <h3>{String(allVisitors.length).padStart(2, '0')}</h3>
              <p>Total Visitors</p>
            </div>
            <div className={styles.statContainer__stat__right}>
              <div className={styles.statContainer__stat__right_icon} style={{ backgroundColor : '#EEF7FF' }}>
                  <img src={TotalVisitorIcon} />
              </div>
            </div>
          </div>
          <div className={styles.statContainer__stat}>
            <div className={styles.statContainer__stat__left}>
              <h3>{String(newDeliveries.length).padStart(2, '0')}</h3>
              <p>Deliveries Arrived</p>
            </div>
            <div className={styles.statContainer__stat__right}>
              <div className={styles.statContainer__stat__right_icon} style={{ backgroundColor : '#FFEEEE' }}>
                  <img src={DeliveriesIcon} />
              </div>
            </div>
          </div>
          <div className={styles.statContainer__stat}>
            <div className={styles.statContainer__stat__left}>
              <h3>{String(preRegVisitors.length).padStart(2, '0')}</h3>
              <p>Pre-Registered Visitors</p>
            </div>
            <div className={styles.statContainer__stat__right}>
              <div className={styles.statContainer__stat__right_icon} style={{ backgroundColor : '#EEF7FF' }}>
                  <img src={TotalVisitorIcon} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.main}>
          <div className={styles.left}>
            <Card>
              <div className={styles.head}>
                <div className='gb_title'>
                  Visitors
                </div>
                <div className={styles.buttons}>
                  <CheckPermission permission="visitors_sign_in">
                    <Button text="+ Sign In" onClick={()=> setSignInModalOpen(true)} classNames="dashboard_btn" />
                  </CheckPermission>
                  <CheckPermission permission="visitors_sign_out_all">
                    <Button text="Sign Out All" classNames="dashboard_btn" disabled={currentVisitors.length == 0} backgroundColor="#F87171" onClick={() => setSignOutAllModal(true)} />
                  </CheckPermission>
                  <CheckPermission permission="pre_reg_add_new">
                    <Button text="Pre-Register" classNames="dashboard_btn" backgroundColor="#589AD4" onClick={() => setPreRegisterModal(true)} />
                  </CheckPermission>
                  {/* <Button text="Refresh" onClick={refreshAccessToken} /> */}
                </div>
              </div>
              {!loading ?
              <>
              <Row gap="1rem" classNames="mt-3 mb-2">
                <TextInput placeholder="Search by name" flex={2} value={searchVisitorName} onChange={(e) => setSearchVisitorName(e.target.value)} />
                <SelectInput placeholder="Select Tenant" flex={1.5} options={tenants} defaultValue={selectedTenant} onChange={(e) => setSelectedTenant(e)} />
              </Row>
              
              <div className={styles.visitorList}>
                {totalItems === 0 ? (
                    <p>No Visitors Signed In</p>
                  ) : (
                    <>
                    {paginatedData.map((visitor) => {
                      return (
                        <>
                          <div className={styles.visitorCard} onClick={() => onVisitorCardClick(visitor)}>
                            <div className={styles.left}>
                              <div className={styles.name}>
                                <h4>{visitor.visitor_name}</h4>
                                {visitor.is_pre_registered ? <p className={styles.label}>PRE-REG</p> : '' }
                              </div>
                              {visitor.tenant_name && <p className={styles.host}>Tenant : {visitor.tenant_name}</p>}
                            </div>
                            <div className={styles.right}>
                              <p className='faded-text text-reg'>Signed in at</p>
                              <p className='text-bold text-reg text-black-light'>{dateTimeFormat(visitor.sign_in_time, "MMM Do YYYY, h:mm a")}</p>
                            </div>
                          </div>
                        </>
                      )})}
                      <div className='mt-3'>
                      <ReactPaginate
                          nextLabel=">>"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={3}
                          marginPagesDisplayed={2}
                          pageCount={totalPages}
                          previousLabel="<<"
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                        />
                      </div>
                    </>
                      )
                  
                }
              </div>
              </> : 
                <div className='mt-3'>
                  <div style={{ display: 'flex', width: '100%', gap: '1rem' }}>
                    <div style={{ width : '100%' }}>
                        <Skeleton height={35} width="100%" />
                    </div>
                    <div style={{ width : '100%' }}>
                        <Skeleton height={35} width="100%" />
                    </div>
                  </div>
                  <div className='mt-3'>
                      <Skeleton height={40} />
                  </div>
                  <div className="mt-2">
                      <Skeleton height={40} />
                  </div>
                  <div className="mt-2">
                      <Skeleton height={40} />
                  </div>
                </div>
              }
            </Card>
          </div>
          <div className={styles.right}>
            <Card classNames={styles.preRegVisitors}>
              <div className='gb_title mb-2'>
                Newly Arrived Deliveries
              </div>
              {!loading ?
              <>
                {/* <div className='mt-2'>
                  <TextInput placeholder="Search by name" value={searchPreRegName} onChange={(e) => setSearchPreRegName(e.target.value)} />
                </div> */}
                <DataTable
                    columns={deliveryColumns}
                    data={newDeliveries}
                    onRowClicked={(row) => onUnAssignedDeliveryClick(row)}
                />
              </> : 
                <TableSkeleton classNames="mt-2" />
              }
            </Card>
            <Card classNames={styles.preRegVisitors}>
              <div className='gb_title'>
                Today's Pre-Registered Visitors
              </div>
              {!loading ?
              <>
                <div className='mt-2'>
                  <TextInput placeholder="Search by name" value={searchPreRegName} onChange={(e) => setSearchPreRegName(e.target.value)} />
                </div>
                <DataTable
                    columns={columns}
                    data={preRegVisitors.filter((visitor) => {
                      if(searchPreRegName) {
                        return visitor.visitor_name.toLowerCase().includes(searchPreRegName.toLowerCase())
                      } else return visitor
                    })}
                    onRowClicked={(row) => onPreRegVisitorClick(row)}
                    highlightOnHover
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[3, 5, 10, 15, 20]}
                />
              </> : 
                <TableSkeleton classNames="mt-2" />
              }
            </Card>
          </div>
        </div>
      </DashboardLayout>
      {signInModalOpen && 
        <SignInModal 
          isModalOpen={signInModalOpen} 
          closeModal={closeSignInModal}
          selectedPreRegUser={selectedPreRegUser}
          getCurrentVisitorsData={fetchData} 
        /> 
      }
      {visitorModal &&
        <VisitorDetailsModal 
          isModalOpen={visitorModal} 
          closeModal={closeVisitorModal}
          selectedVisitor={selectedVisitor}
          getCurrentVisitorsData={getCurrentVisitorsData}  
        /> 
      }
      {preRegisterModal &&
        <PreRegisterModal 
          isModalOpen={preRegisterModal} 
          closeModal={closePreRegisterModal} 
        />
      }
      {signOutAllModal && 
        <ConfirmSignOutAllModal 
          isModalOpen={signOutAllModal} 
          closeModal={closeSignOutAllModal} 
          signOutAll={signOutAll}
        /> 
      }
      {confirmDeliveryModalOpen && 
        <ConfirmDeliveryModal 
            isModalOpen={confirmDeliveryModalOpen} 
            closeModal={closeModal} 
            selectedDelivery={selectedUnAssignedDelivery}
            fetchData={fetchData}
            mode={confirmModalMode}
        />
    }
    </>
  )
}


const ConfirmSignOutAllModal = ({isModalOpen, closeModal, signOutAll}) => {
  const [loading, setLoading] = useState(false);

  const signOutAllVisitors = async () => {
      setLoading(true);
      await signOutAll();
      setTimeout(() => {
          setLoading(false);
      }, 500);
      
  }

  const SignOutBody = () => {
      return (
          <>
              <p>Are you sure you want to sign out all the current visitors of the building ?</p>
          </>
      )
  }

  const SignOutFooter = ({closeModal}) => {
      return (
          <Row gap="1rem" justify="flex-end">
              <Button text="Cancel" backgroundColor="#D3D3D3" color="#3A3A3A" onClick={closeModal} />
              <Button text="Yes, Sign Out All" disabled={loading} loading={loading} loadingText="Signing Out..." backgroundColor="#FD4848" onClick={signOutAllVisitors} />
          </Row>
      )
  }

  return (
      <CustomModal isModalOpen={isModalOpen} closeModal={closeModal} width="40%" minHeight="30%">
          {{ 
              header : <h2>Confirm Sign Out</h2>,
              body : <SignOutBody />,
              footer : <SignOutFooter closeModal={closeModal} />
           }}
      </CustomModal>
  )
}

export default DashboardScreen