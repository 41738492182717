import { useContext } from "react";
import { useNavigate } from "react-router-dom"
import Skeleton from 'react-loading-skeleton'
import BreadCrumbContext from "contexts/breadcrumbContext";
import DashboardLayout from "layouts/dashboardLayout/dashboard.jsx"
import Card from "components/card/card"
import TextInput from "components/inputs/textInput/textInput"
import Button from "components/buttons/button"
import GracechurchLogo from 'assets/images/6 Gracechurch.png';
import DefaultBuilding from 'assets/images/Building.png';
import AddNewSiteModal from "components/modal/addNewSiteModal/addNewSiteModal";
import Row from "layouts/row/row"
import useAllSites from "./useAllSites";
import 'react-loading-skeleton/dist/skeleton.css'
import styles from './allSites.module.scss'
import CheckPermission from "components/checkPermission/checkPermission";

const AllSitesScreen = () => {

    const {
        addNewSiteModal,
        setAddNewSiteModal,
        closeAddNewSiteModal,
        sites,
        loading,
        searchBy, setSearchBy,
        fetchAllSites,
        multiTenant, setMultiTenant,
        siteName, setSiteName,
        error, setError,
        errorMessages, setErrorMessages,
        onButtonClick,
        siteAddLoading, setSiteAddLoading
    } = useAllSites();

    const navigate = useNavigate();

    const {
        managedSiteName, setManagedSiteName,
        managedSiteId, setManagedSiteId,
        setBreadcrumbData
    } = useContext(BreadCrumbContext);

    function Box({ children }) {
        return (
          <div
            style={{
              border: '0.1px solid #ebebeb',
              borderRadius : '5px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent : 'center',
              lineHeight: 2,
              padding: '1rem',
              marginBottom: '0.5rem',
              width: 200,
              height: 200
            }}
          >
            {children}
          </div>
        );
      }

  return (
    <>
        <DashboardLayout>
            <div className="gb-head">
                <h2>Manage Sites</h2>
            </div>
            <Card>
                <div className={styles.topContainer}>
                    <div className={styles.left}>
                        <TextInput showError={false} value={searchBy} onChange={(e) => setSearchBy(e.target.value)} flex={1} placeholder="Search by name" />
                    </div>
                    <div className={styles.right}>
                        <CheckPermission permission="sites_add_edit">
                            <Button text="+ Add New Site" onClick={() => setAddNewSiteModal(true)} />
                        </CheckPermission>
                    </div>
                </div>
                {!loading ?
                <div className={styles.sitesContainer}>
                    {sites.filter((site) => site.attributes.name.toLowerCase().includes(searchBy.toLowerCase())).map((site, index) => {
                        return (
                            <div className={styles.site} onClick={() => {setBreadcrumbData("site", {id : site.attributes.building_id, name : site.attributes.name}); navigate(`/manage/sites/${site.attributes.building_id}`)}} key={index}>
                                <div className={styles.siteImg}>
                                    <img src={DefaultBuilding} />
                                </div>
                                <p>{site.attributes.name}</p>
                            </div>
                        )
                    })}
                </div> :
                <Row gap="1rem" classNames="mt-3">
                    <Box>
                        <Row justify="center">
                            <Skeleton circle width={70} height={70} />
                        </Row>
                        <div className="mt-2">
                            <Skeleton count={2} />
                        </div>
                    </Box>
                    <Box>
                        <Row justify="center">
                            <Skeleton circle width={70} height={70} />
                        </Row>
                        <div className="mt-2">
                            <Skeleton count={2} />
                        </div>
                    </Box>
                    <Box>
                        <Row justify="center">
                            <Skeleton circle width={70} height={70} />
                        </Row>
                        <div className="mt-2">
                            <Skeleton count={2} />
                        </div>
                    </Box>
                </Row> }
            </Card>
        </DashboardLayout>

        <AddNewSiteModal 
            isModalOpen={addNewSiteModal} 
            closeModal={closeAddNewSiteModal} 
            fetchAllSites={fetchAllSites}
            properties={{ 
                multiTenant, setMultiTenant,
                siteName, setSiteName,
                error, setError,
                errorMessages, setErrorMessages,
                onButtonClick,
                siteAddLoading, setSiteAddLoading
            }} 
        />
    </>
  )
}

export default AllSitesScreen