import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from 'contexts/authContext';
import * as Ably from 'ably';
import { AblyProvider } from 'ably/react';
import { createRoot } from 'react-dom/client';

const root = ReactDOM.createRoot(document.getElementById('root'));

const client = new Ably.Realtime.Promise({ key: 'GDni9A.iLJeIA:2o6qeQ4c0CqUIqv1igNWsJ54PyqtSUruyu097IToB60', clientId: 'web-dashboard' });

root.render(
    <AblyProvider client={client}>
        <AuthProvider>
            <App />
        </AuthProvider>
    </AblyProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
