import React                from 'react'
import DashboardLayout      from 'layouts/dashboardLayout/dashboard.jsx';
import Button               from 'components/buttons/button';
import Card                 from 'components/card/card';
import ConfirmDeliveryModal from 'components/modal/confirmDeliveryModal/confirmDeliveryModal';
import DeliveryDetailsModal from 'components/modal/deliveryDetailsModal/deliveryDetailsModal';
import CollectionDetailsModal from 'components/modal/collectionDetailsModal/collectionDetailsModal';
import styles               from './deliveries.module.scss';
import DataTable            from 'react-data-table-component';
import SelectInput          from 'components/inputs/selectInput/selectInput';
import useDeliveries        from './useDeliveries';

const DeliveriesScreen = () => {

    const {
        columns,
        unassignedColumns,
        assignedDeliveries,
        unassignedDeliveries,
        confirmDeliveryModalOpen,
        setConfirmDeliveryModalOpen,
        closeModal,
        deliveryDetailsModalOpen, 
        setDeliveryDetailsModalOpen,
        closeDeliveryDetailsModal,
        onUnAssignedDeliveryClick,
        onAssignedDeliveryClick,
        selectedUnAssignedDelivery, setSelectedUnAssignedDelivery,
        selectedAssignedDelivery,
        showCollectionDetailsModal, setShowCollectionDetailsModal,
        fetchData,
        addDeliveryClick,
        selectedTenant, setSelectedTenant,
        onTenantChange,
        filteredAssignedDeliveries,
        tenantOptions, setTenantOptions,
        confirmModalMode
    } = useDeliveries();

  return (
    <>
    <DashboardLayout>
        <div className="gb-head">
            <h2>Deliveries</h2>
            <div className="gb-head__right">
                <Button text="+ Add New Delivery" onClick={addDeliveryClick} />
            </div>
        </div>
        <div className={styles.deliveriesContainer}>
            <Card flex={2}>
                <div className={styles.cardHeader}>
                    Assigned Deliveries
                </div>
                <div className={styles.cardBody}>
                    <div className={styles.inputContainer}>
                        <SelectInput placeholder="Select Tenant" options={tenantOptions} defaultValue={selectedTenant} onChange={onTenantChange} />
                    </div>
                    <div className="mt-2">
                        <DataTable
                            columns={columns}
                            data={filteredAssignedDeliveries}
                            onRowClicked={(row) => onAssignedDeliveryClick(row)}
                            highlightOnHover
                            pagination
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[3, 5, 10, 15, 20]}
                            paginationComponentOptions={{
                                rowsPerPageText: "Records per page:",
                                rangeSeparatorText: "out of"
                            }}
                        />
                    </div>
                </div>
            </Card>
            <Card flex={1.3}>
            <div className={`${styles.cardHeader} ${styles.unassigned}`}>
                    Unassigned Deliveries
                </div>
                <div className={styles.cardBody}>
                    <div className="mt-2">
                        <DataTable
                            columns={unassignedColumns}
                            data={unassignedDeliveries}
                            onRowClicked={(row) => onUnAssignedDeliveryClick(row)}
                            highlightOnHover
                            pagination
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[3, 5, 10, 15, 20]}
                            paginationComponentOptions={{
                                rowsPerPageText: "Records per page:",
                                rangeSeparatorText: "out of"
                            }}
                        />
                    </div>
                </div>
            </Card>
        </div>
    </DashboardLayout>

    <DeliveryDetailsModal isModalOpen={deliveryDetailsModalOpen} closeModal={closeDeliveryDetailsModal} />

    {showCollectionDetailsModal &&
        <CollectionDetailsModal 
            isModalOpen={showCollectionDetailsModal} 
            closeModal={() => setShowCollectionDetailsModal(false)} 
            selectedDelivery={selectedAssignedDelivery}
            fetchData={fetchData}
        />
    }

    {confirmDeliveryModalOpen && 
        <ConfirmDeliveryModal 
            isModalOpen={confirmDeliveryModalOpen} 
            closeModal={closeModal} 
            selectedDelivery={selectedUnAssignedDelivery}
            fetchData={fetchData}
            mode={confirmModalMode}
        />
    }
    </>
  )
}

export default DeliveriesScreen