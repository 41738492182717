import { useState, useEffect, useRef }                      from "react"
import { useParams }                                from "react-router-dom";
import { DragDropContext, Droppable, Draggable }    from "react-beautiful-dnd"
import {BiSolidPencil}                              from 'react-icons/bi';
import { IoMdCheckmark } from "react-icons/io";
import {RxHamburgerMenu}                            from 'react-icons/rx';
import { MdDelete }                                 from "react-icons/md";
import CreatableSelectInput from "components/inputs/creatableSelectInput/creatableSelectInput";
import { fieldLabelColors }                         from 'constants/colors'
import { showErrorToast, showSuccessToast }         from "functions/toasts";
import SearchDropdown                               from "components/inputs/searchDropdown/searchDropdown";
import { capitalizeWords, checkIfEmpty }            from "functions/functions";
import Skeleton                                     from "react-loading-skeleton";
import Checkbox                                     from "components/inputs/checkbox/checkbox";
import { createPortal } from "react-dom";
import useApi                                       from "hooks/useApi";
import TextInput                                    from "components/inputs/textInput/textInput"
import SelectInput                                  from "components/inputs/selectInput/selectInput"
import Icon                                         from "components/icon/icon"
import Button                                       from "components/buttons/button"
import AddNewFieldModal                             from "../addNewFieldModal/addNewFieldModal"
import CustomModal                                  from "../modal"
import styles                                       from './addNewTemplateModal.module.scss';
import CheckPermission from "components/checkPermission/checkPermission";
import Col from "layouts/col/col";



// Body of the Template Modal
const Body = ({
    templateType,
    templateName,
    setTemplateName,
    tenantOptions,
    selectedTenant,
    setSelectedTenant,
    tenantsList, setTenantsList,
    mockTenantsList, setMockTenantsList,
    form,
    setForm,
    required,
    tenantId,
    error, setError,
    errorMessages, setErrorMessages,
    setRequired,
    selectedTemplate,
    mode,
    loading, setLoading
}) => {

    console.log(form);

    const [addNewFieldVisible, setAddNewFieldVisible]       = useState(false);
    const [field, setField]                                 = useState('');
    const [fieldModalMode, setFieldModalMode]               = useState('add');
    const [showAddNewFieldModal, setShowAddNewFieldModal]   = useState(false);
    const [selectedField, setSelectedField]                 = useState('');
    const [fieldNameOptions, setFieldNameOptions]           = useState([]);
    const [menuOpen, setMenuOpen]                           = useState(false);
    const [newFieldValue, setNewFieldValue]                 = useState('');

    const addFieldRef = useRef(null);

    const {id} = useParams();
    const {get, post} = useApi();

    useEffect(() => {
        fetchSiteFields();
    },[]);

    // Fetch Form Fields of the site
    const fetchSiteFields = async () => {

        setLoading(true);

        try {

            const response = await get(`buildings/${id}/fields`);
            const data = response.data.data;

            console.log(data)

            let fieldNameOptions = [];

            data.forEach((field) => {
                fieldNameOptions.push({
                    value : field.name,
                    label : field.name,
                    displayLabel : field.label,
                    default : field.default,
                    type : field.type,
                    ...(field.type === 'dropdown' && {dropdownOptions : field.options})
                });
            })

            console.log(fieldNameOptions);

            // fieldNameOptions.push({
            //     value : 'add',
            //     label : '+ Create New Field',
            //     mode : 'create'
            // })

            setFieldNameOptions(fieldNameOptions);

            // setSiteFields(data);

            setLoading(false);

        } catch(err) {
            console.log(err);
        }
    }

    // Fucntion to close Field Modal
    const closeAddNewFieldModal = async () => {
        setMenuOpen(false);
        setShowAddNewFieldModal(false);
        await fetchSiteFields();
    }

    // Field Reordering Function
    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
          }
      
          const updatedList = Array.from(form.fields);
          const [reorderedItem] = updatedList.splice(result.source.index, 1);
          updatedList.splice(result.destination.index, 0, reorderedItem);
      
          setForm(form => ({
            ...form,
            fields : updatedList
          }));
    }

    // Options for Required Select Option
    const requireTypeOptions = [
        { value: true, label: 'Required' },
        { value: false, label: 'Not Required' },
    ];

    // Function to execute when an option is selected from fields
    const onFieldSelected = (e) => {
        setField(e)
    }

    const onCreateFieldClicked = (e) => {
        console.log('New Value', e)
        setNewFieldValue(e);
        setFieldModalMode('add');
        setShowAddNewFieldModal(true);
    }

    const onEmployeesEditClick = () => {
        setFieldModalMode('edit');
        setSelectedField(form.employees);
        console.log(form.employees);
        setShowAddNewFieldModal(true);
    }

    // Editing existing field in the template
    const onEditClick = (field) => {
        console.log(field);
        setFieldModalMode('edit');
        setSelectedField(field);
        setShowAddNewFieldModal(true);
    }

    // Deleting a field from the template
    const onDeleteClick = (fieldId) => {
        console.log(form);
        console.log(fieldId);
        let updatedArray = form.fields.filter((field) => field.fieldId !== fieldId);

        setForm(form => ({
            ...form,
            fields : updatedArray
        }));
    }

    const onPersonVisitClick = () => {
        setForm(form => ({
            ...form,
            employees : {
                ...form.employees,
                enabled : true
            }
        }))
    }

    const onPersonVisitRemove = () => {
        setForm(form => ({
            ...form,
            employees : {
                ...form.employees,
                enabled : false
            }
        }))
    }

    // Function to add a new field
    const addNewField = () => {
        console.log(field)
        let errorObj = {
            ...error,
            field : false
        }

        let errorMessageObj = {
            ...errorMessages,
            field : ''
        }

        if(!field) {
            errorObj.field = true;
            errorMessageObj.field = 'Select a field'
        }

        if(!errorObj.field) {
            console.log(field)
            const len = form.fields.length;
            // setForm([...form, {
            //     fieldId : `item-${len + 1}`,
            //     name : field.value,
            //     label : field.displayLabel,
            //     type : field.type,
            //     required : required.value
            // }])
            let newFieldData = {
                ...form,
                fields : [
                    ...form.fields,
                    {
                        fieldId : `item-${len + 1}`,
                        name : field.value,
                        label : field.displayLabel,
                        type : field.type,
                        required : required.value,
                        ...(field.type === 'dropdown' && {dropdownOptions : field.dropdownOptions})
                    }
                ]
            }

            console.log('New Field',newFieldData);

            setForm(newFieldData);
            setRequired({ value: true, label: 'Required' });
            setAddNewFieldVisible(false)
        }

        setField('')
        setError(errorObj)
        setErrorMessages(errorMessageObj)
        
    }

    // Function to update the field of the template (label, type, required. => name is disabled)
    const updateTemplateField = (selectedField, displayLabel, fieldType, required, options) => {
        console.log(selectedField, displayLabel, fieldType, required);
        if(selectedField.fieldId === "employees") {
            setForm(form => ({
                ...form,
                employees : {
                    ...form.employees,
                    label : displayLabel,
                    required : required ? true : false
                }
            }))
        } else {
            const updatedForm = form.fields.map((field) => {
                if(field.fieldId === selectedField.fieldId) {
                    return {
                        ...field,
                        label : displayLabel,
                        type : fieldType.value,
                        required : required ? true : false,
                        ...(field.type === 'dropdown' && {dropdownOptions : options})
                    }
                }
                return field;
            });
            console.log(updatedForm)
            setForm(form => ({
                ...form,
                fields : updatedForm
            }));
        }
        
        closeAddNewFieldModal();
    }

    // Custom styles for field options
    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.data && state.data.value === 'add' ? 'lightblue' : state.isSelected ? 'green' : 'white',
          color: state.isSelected ? 'white' : 'black'
          // Add any other styles you want for regular options
        }),
        // Add any other styles as needed
      };

    // When visitor photo checkbox is changed
    const onVisitorPhotoChanged = () => {
        setForm(form => ({
            ...form,
            visitorPhoto : {
                required : !form.visitorPhoto.required
            }
        }))
    }

    
    const onAssignChange = (tenant, status) => {
        
        const updatedList = mockTenantsList.map((item) => {

            if(item.tenant_id === tenant.tenant_id) {
                console.log(item.checked);
                console.log(status)
                const changeStatus = item.checked !== status;
                console.log('Change', changeStatus)
                return {
                    ...item,
                    checked : status,
                    changed : item.checked !== status
                }
            } 
            return item;
        })

        setTenantsList(updatedList);
    }

    return (
        <>
        {!loading ?
            <div className={styles.templateFormContainer}>
                <div className={styles.templateEditor} style={{ width : templateType == 'global' && mode == 'edit' ? '70%' : '100%'  }}>
                    <div className={`${styles.templateInfoContainer}`} style={{ width : templateType == 'global' ? '50%' : '100%'  }}>
                        <TextInput 
                            label="Template Name"
                            isError={error.name}
                            errorMessage={errorMessages.name} 
                            placeholder="Enter Template Name"
                            disabled={selectedTemplate.default_template}
                            value={templateName}
                            onChange={(e) => setTemplateName(e.target.value)} 
                            required 
                        />
                        {templateType == 'tenant' && 
                            <SelectInput 
                                defaultValue={selectedTenant} 
                                options={tenantOptions} 
                                onChange={(e) => setSelectedTenant(e)} 
                                label="Select Tenant"
                                disabled={mode==='edit' || tenantId} 
                                placeholder="Enter Tenant"
                                showError 
                                required
                                isError={error.tenantName}
                                errorMessage={errorMessages.tenantName} 
                            /> }
                    </div>
                    <div className={styles.templateFormListContainer}>
                        <p className="gb_title">Template Form</p>
                        <div className={styles.fields}>
                                    <div className={styles.fields__constant}>
                                        <div className={styles.field} style={{ backgroundColor :  '#fcfffd', cursor : 'no-drop'}}>
                                            <div className={styles.left}>
                                                <p className={styles.fieldName}>Visitor Name</p>
                                            </div>
                                        </div>
                                        <div className={styles.field} style={{ backgroundColor :  '#fcfffd', cursor : 'no-drop'}}>
                                            <div className={styles.left}>
                                                <p className={styles.fieldName}>Company Visiting</p>
                                            </div>
                                        </div>
                                        {form.employees.enabled ?
                                        <div className={styles.field} style={{ backgroundColor :  '#fcfffd'}}>
                                            <div className={styles.left}>
                                                <div className={styles.left__top}>
                                                    <p className={styles.fieldName}>{form.employees.name}</p>
                                                    <div className={styles.labels}>
                                                        {/* <div className={styles.label} style={{ backgroundColor : fieldLabelColors[field.type] }}>{capitalizeWords(field.type)}</div> */}
                                                        <div className={styles.label} style={{ backgroundColor : fieldLabelColors[form.employees.required === true ? 'required' : 'notRequired'] }}>{form.employees.required === true ? 'Required' : 'Not Required'}</div>
                                                    </div>
                                                </div>
                                                <p className={styles.label}>{form.employees.label}</p>
                                            </div>
                                            <div className={styles.rightOptions}>
                                                <div className={styles.edit} onClick={onPersonVisitRemove}>
                                                    <Icon icon={<MdDelete />} />
                                                    <p>Remove</p>
                                                </div>
                                                <div className={styles.edit} onClick={onEmployeesEditClick}>
                                                    <Icon icon={<BiSolidPencil />} />
                                                    <p>Edit</p>
                                                </div>
                                                <div style={{ opacity: 0 }}>
                                                    <Icon icon={<RxHamburgerMenu />} size="2rem" color="#A0A0A0" />
                                                </div>
                                            </div>
                                        </div> :
                                        <div className={styles.field} style={{ backgroundColor :  '#F8F8F8', borderColor : '#e0e0e0'}}>
                                            <div className={styles.left}>
                                                <p className={styles.fieldName} style={{ color : '#b3b3b3' }}>{form.employees.name}</p>
                                            </div>
                                            <div className={styles.rightOptions}>
                                                <div className={styles.edit} onClick={() => onPersonVisitClick()}>
                                                    <Icon icon={<IoMdCheckmark />} size="1.8rem" color="#009c27" />
                                                    <p style={{ color : '#009c27', fontWeight : 500 }}>Enable</p>
                                                </div>
                                                {/* <div className={styles.edit}>
                                                    <Icon icon={<BiSolidPencil />} />
                                                    <p>Edit</p>
                                                </div>
                                                <div style={{ opacity: 0 }}>
                                                    <Icon icon={<RxHamburgerMenu />} size="2rem" color="#A0A0A0" />
                                                </div> */}
                                            </div>
                                        </div>}
                                    </div>

                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId="sortable-list">
                                    {(provided) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef} className={styles.draggableContainer}>
                                            {form?.fields?.filter((item) => item.name !== "Visitor Name")?.map((field, index) => (
                                                <Draggable draggableId={field.fieldId} index={index} key={field.fieldId}>
                                                    {(provided) => (
                                                        <div className={styles.field} 
                                                            {...provided.draggableProps} 
                                                            {...provided.dragHandleProps} 
                                                            ref={provided.innerRef}
                                                        >
                                                            <div className={styles.left}>
                                                                <div className={styles.left__top}>
                                                                    <p className={styles.fieldName}>{field.name}</p>
                                                                    <div className={styles.labels}>
                                                                        <div className={styles.label} style={{ backgroundColor : fieldLabelColors[field.type] }}>{capitalizeWords(field.type)}</div>
                                                                        <div className={styles.label} style={{ backgroundColor : fieldLabelColors[field.required === true ? 'required' : 'notRequired'] }}>{field.required === true ? 'Required' : 'Not Required'}</div>
                                                                    </div>
                                                                </div>
                                                                <p className={styles.label}>{field.label}</p>
                                                            </div>
                                                            <CheckPermission permission="sites_add_edit">
                                                                <div className={styles.rightOptions}>
                                                                    <div className={styles.edit} onClick={() => onDeleteClick(field.fieldId)}>
                                                                        <Icon icon={<MdDelete />} />
                                                                        <p>Remove</p>
                                                                    </div>
                                                                    <div className={styles.edit} onClick={() => onEditClick(field)}>
                                                                        <Icon icon={<BiSolidPencil />} />
                                                                        <p>Edit</p>
                                                                    </div>
                                                                    <Icon icon={<RxHamburgerMenu />} size="2rem" color="#A0A0A0" />
                                                                </div>
                                                            </CheckPermission>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            {addNewFieldVisible && <div className={styles.newFieldContainer}>
                                {/* <SelectInput placeholder="Search Field Name" isError={error.field} flex={2}  options={fieldNameOptions} onChange = {onFieldSelected} customStyles={customStyles} /> */}
                                <CreatableSelectInput 
                                    isClearable
                                    placeholder="Search Field Name"
                                    flex={2} 
                                    menuIsOpen={menuOpen}
                                    onMenuOpen={() => setMenuOpen(true)}
                                    onMenuClose={() => setMenuOpen(false)}
                                    options={fieldNameOptions} 
                                    defaultValue={field} 
                                    isError={error.field} 
                                    onChange = {onFieldSelected} 
                                    onCreateOption={onCreateFieldClicked} 
                                />
                                {/* <SearchDropdown showError={false} placeholder="Search Field" onChange = {onFieldSelected} options={fieldNameOptions} /> */}
                                <SelectInput placeholder="Required" options={requireTypeOptions} defaultValue={required} onChange = {(e) => setRequired(e)} />
                                <Button backgroundColor="#002547" text="Add Field" onClick={addNewField} />
                                <p className={styles.cancel} onClick={() => setAddNewFieldVisible(false)}>Cancel</p>
                            </div> }
                        </div>
                        <CheckPermission permission="sites_add_edit">
                            <Col gap="4rem">
                                <div>
                                    {!selectedTemplate.default_template && <p className={styles.addFieldTxtBtn} onClick={() => setAddNewFieldVisible(true)}>+ Add New Field</p> }
                                </div>
                                <Checkbox label="Visitor Photo is Required" checked={form.visitorPhoto.required} onChange={onVisitorPhotoChanged} />
                            </Col>
                        </CheckPermission>
                    </div>
                </div>

                {templateType !== 'tenant' && mode == 'edit' &&
                <div className={styles.assignedTenantsContainer}>
                    <p className="gb_title">Used By</p>
                    <div className={styles.tenantsList}>
                        {tenantsList.map((tenant) => {
                            if(tenant.checked) {
                                return (
                                    <Checkbox label={tenant.name} checked={tenant.checked}  />
                                )
                            }
                        })}
                       
                    </div>
                </div> }
            </div> :
                // Loader View 
                <div className="mt-3">
                    <div>
                        <Skeleton width="20%" />
                        <Skeleton height={40} />
                    </div>
                    <div className="mt-2">
                        <Skeleton width="20%" />
                        <Skeleton height={100} />
                    </div>
                    <div className="mt-2">
                        <Skeleton width="20%" height={30} />
                    </div>
                    <div className="mt-2">
                        <Skeleton width="20%" height={30} />
                    </div>
                </div>
            }
            {showAddNewFieldModal ? 
            <AddNewFieldModal 
                isModalOpen={showAddNewFieldModal} 
                closeModal={closeAddNewFieldModal} 
                selectedField={selectedField}
                updateTemplateField={updateTemplateField} 
                fetchSiteFields={fetchSiteFields}
                setField={setField}
                newFieldValue={newFieldValue} 
                mode={fieldModalMode} 
                data={{ fieldName : 'Email' }} 
            /> 
            : '' }
        </>
    )
}


const Footer = ({addTemplate, mode, selectedTemplate}) => {
    return (
        <div className={styles.footerContainer}>
            <CheckPermission permission="sites_add_edit">
                <Button text={mode === 'add' ? `Add Template` : `Update Template`} onClick={addTemplate} disabled={selectedTemplate.default_template} />
            </CheckPermission>
        </div>
    )
}

const AddNewTemplateModal = ({isModalOpen, closeModal, mode, fetchTemplates, selectedTemplate, templateType, tenantId}) => {

    console.log(fetchTemplates);

    const [templateName, setTemplateName]           = useState('');
    const [form, setForm]                           = useState(
        {
            employees : {
                name : "Employees",
                enabled : false,
                required : true,
                label : "Person Visiting",
                fieldId : 'employees'
            },
            fields : [],
            visitorPhoto : {
                required : true
            }
        }
    );
    const [required, setRequired]                   = useState({ value: true, label: 'Required' });
    const [error, setError]                         = useState('');
    const [errorMessages, setErrorMessages]         = useState('');
    const [tenantOptions, setTenantOptions]         = useState([]);
    const [selectedTenant, setSelectedTenant]       = useState('');
    const [tenantsList, setTenantsList]             = useState([]);
    const [mockTenantsList, setMockTenantsList]     = useState([]);
    const [assignedTenants, setAssignedTenants]     = useState([]);
    const [loading, setLoading]                     = useState(true);

    const {id}      = useParams();
    const {post, get}    = useApi();

    useEffect(() => {
        if(mode === 'edit') {
            setTemplateData();
        } 

        if(templateType === 'tenant') {
            setTenantsDropDownOption();
        }
        
        if(templateType === 'global') {
            fetchTenantsList();
        }
    },[]);


    const fetchTenantsList = async () => {
        setLoading(true);
        const data = await fetchTenants();
        let assignedTenantsList = [];
        console.log(data)
        data.forEach((tenant) => {
            let tenantObj = {
                name : tenant.attributes.name,
                tenant_id : tenant.attributes.tenant_id,
                changed: false
            }

            if(mode === 'edit') {
                if(tenant.attributes.template_id === selectedTemplate.template_id) {
                    tenantObj['checked'] = true;
                } else {
                    tenantObj['checked'] = false;
                }
            } else {
                tenantObj['checked'] = false;
            }

            if(tenant.attributes.template_id) {
                if(tenant.attributes.template_id === selectedTemplate.template_id) {
                    tenantObj['isDefaultable'] = true;
                } else {
                    tenantObj['isDefaultable'] = false;
                }
                
            } else {
                tenantObj['isDefaultable'] = false;
            }

            assignedTenantsList.push(tenantObj)
            
        })

        console.log(assignedTenantsList);
        setTenantsList(assignedTenantsList);
        setMockTenantsList(assignedTenantsList);
        setLoading(false);
    }


    const setTenantsDropDownOption = async () => {
        console.log('here')
        setLoading(true);
        const data = await fetchTenants();
        let tenantsList = [];
        data.forEach((tenant) => {
            tenantsList.push({
                label : tenant.attributes.name,
                value : tenant.attributes.tenant_id,
            })
        });

        
        setTenantOptions(tenantsList);

        let selectedTenantOption;
        console.log('TENANT ID', selectedTemplate?.tenant);
        if((selectedTemplate?.tenant) || tenantId) {
            console.log(selectedTemplate.tenant);
            console.log(data)
            data.forEach((tenant) => {
                console.log(tenant)
                if(selectedTemplate?.tenant) {
                    if((tenant.attributes.tenant_id === selectedTemplate.tenant.attributes.tenant_id) ) {
                        selectedTenantOption = tenant.attributes
                    }
                } else {
                    if(tenant.attributes.tenant_id == tenantId) {
                        selectedTenantOption = tenant.attributes
                    }
                }
                
            })

            console.log('Selected tenant Option',selectedTenantOption);

            setSelectedTenant({
                label : selectedTenantOption.name,
                value : selectedTenantOption.tenant_id,
            })
        }

        setLoading(false)
    }


    // Fetch all tenants of the site to load in the dropdown
    const fetchTenants = async () => {
        try {
            const response = await get(`buildings/${id}/tenants`);
            const data = response.data.data;

            return data;
            
        } catch(err) {
            showErrorToast('Something Went Wrong');
        }
    }

    // Function to set the values of the templte in edit mode
    const setTemplateData = () => {
        console.log("SELECTED TEMPLATE",selectedTemplate);
        setTemplateName(selectedTemplate.name);

        let fieldsList = [];
        selectedTemplate.form?.fields?.map((field, index) => {
            fieldsList.push({
                fieldId : `item-${index}`,
                name : field.name,
                label : field.label,
                type : field.type,
                required : field.required === "true" ? true : false,
                ...(field.type === 'dropdown' && {dropdownOptions : field.dropdownOptions})
            })
        })
        setForm({
            employees : {
                name : selectedTemplate.form.employees.name,
                label : selectedTemplate.form.employees.label,
                enabled : selectedTemplate.form.employees.enabled === "true" ? true : false,
                fieldId : selectedTemplate.form.employees.fieldId,
                required : selectedTemplate.form.employees.required === "true" ? true : false 
            },
            fields : fieldsList,
            visitorPhoto : {
                required : selectedTemplate.form.visitorPhoto.required === "true" ? true : false
            }
        });
    }

    // Function to close the Template Modal
    const closeTemplateModal = () => {
        setForm([]);
        setTemplateName('');
        closeModal();
    }
    

    // Add New Template Function
    const addTemplate = async () => {

        console.log('form', form);
        setLoading(true);

        let errorObj = {
            name : false,
            tenantName : false
        };

        let errorMessagesObj = {
            name : '',
            tenantName : ''
        }


        // Checking if template name is empty
        if(checkIfEmpty(templateName.trim())) {
            errorObj.name = true;
            errorMessagesObj.name = 'Template Name is mandatory';
        }

        if(templateType == 'tenant') {
            if(!selectedTenant) {
                errorObj.tenantName = true;
                errorMessagesObj.tenantName = "Tenant Name is mandatory";
            }
        }

        let fieldsArray = [];
        form.fields.forEach((item) => {
            fieldsArray.push({
                name : item.name,
                label : item.label,
                type: item.type,
                required : item.required === true ? true : false,
                ...(item.type === 'dropdown' && {dropdownOptions : item.dropdownOptions})
            })
        });

        console.log(fieldsArray);

        const data = {
            name : templateName,
            form : {
                employees : form.employees,
                fields : fieldsArray.length > 0 ? fieldsArray : ' ',
                visitorPhoto : form.visitorPhoto
            },
            photo_req : 0
        }

        if(!errorObj.name && !errorObj.tenantName) {
            let path = '';
            if(mode === 'add') {
                path = templateType === 'tenant' ? `tenants/${selectedTenant.value}/form-templates` : `buildings/${id}/form-templates`;
            } else if(mode === 'edit') {
                path = `form-templates/${selectedTemplate.template_id}`;
            }

            try {
                const res = await post(path, data);
                console.log(res.data.data.template_id);
                tenantsList.forEach(async (tenant) => {
                    console.log(tenant);
                    if(tenant.changed) {
                        let data = {};
                        if(tenant.checked) {
                            data.template_id = res.data.data.template_id;
                        } else {
                            if(tenant.isDefaultable) {
                                data.template_id = null;
                            }
                        }

                        try {
                            await post(`tenants/${tenant.tenant_id}/sign-in-form`, data)
                        } catch(err) {
                            console.log('Here 1')
                            showErrorToast('Something Went Wrong');
                        }
                    }
                })
                
                // Tenant Id would pass if the modal is opened from tenant config
                // From Tenant Config page, fetchTemplates does not need to call when modal is closed
                if(!tenantId) await fetchTemplates();
                
                showSuccessToast(mode === 'add' ? 'New Template Added Successfully' : 'Template Updated Successfully');
                setLoading(false);
                closeModal();
    
            } catch(err) {
                console.log(err);
                console.log('Here 2')
                showErrorToast('Something Went Wrong');
            }
        } else {
            setLoading(false)
            setError(errorObj);
            setErrorMessages(errorMessagesObj);
        }  
    }

  return (
    <>
        <CustomModal isModalOpen={isModalOpen} closeModal={closeTemplateModal} width="65%" minHeight="95%">
            {{ 
                header : `Add New ${templateType == 'global' ? 'Global' : 'Tenant'} Template`,
                body : <Body 
                    templateType={templateType}
                    templateName={templateName}
                    setTemplateName={setTemplateName}
                    tenantOptions={tenantOptions}
                    setTenantOptions={setTenantOptions}
                    selectedTenant={selectedTenant}
                    setSelectedTenant={setSelectedTenant}
                    tenantsList={tenantsList}
                    setTenantsList={setTenantsList}
                    mockTenantsList={mockTenantsList}
                    setMockTenantsList={setMockTenantsList}
                    form={form}
                    setForm={setForm}
                    required={required}
                    setRequired={setRequired}
                    selectedTemplate={selectedTemplate}
                    tenantId={tenantId}
                    error={error} setError={setError}
                    errorMessages={errorMessages} setErrorMessages={setErrorMessages}
                    loading={loading}
                    setLoading={setLoading}
                    mode={mode} 
                />,
                footer : <Footer mode={mode} addTemplate={addTemplate} selectedTemplate={selectedTemplate} />
            }}
        </CustomModal>
        
    </>
  )
}

export default AddNewTemplateModal