import DashboardLayout      from "layouts/dashboardLayout/dashboard.jsx";
import DataTable            from 'react-data-table-component';
import VisitorDetailsModal  from "components/modal/visitorDetailsModal/visitorDetailsmodal";
import Button               from "components/buttons/button";
import Card                 from "components/card/card";
import TextInput            from "components/inputs/textInput/textInput";
import SelectInput          from "components/inputs/selectInput/selectInput";
import { CSVLink }          from "react-csv";
import { CiExport }         from "react-icons/ci";
import styles               from './todaysVisitors.module.scss'
import useTodaysVisitors    from "./useTodaysVisitors";

// Custom hook for the today's visitors page
const TodaysVisitors = () => {

    const {
        modalOpen,
        setModalOpen,
        columns,
        data,
        filteredData, setFilteredData,
        tenantOptions,
        statusOptions,
        visitorName, setVisitorName,
        selectedTenant, setSelectedTenant,
        rowClick,
        closeModal,
        selectedVisitor, setSelectedVisitor,
        fetchTodaysVisitors,
        onTenantChange,
        header,
        loading, setLoading
    } = useTodaysVisitors();

    console.log(selectedTenant);

    return (
        <>
            <DashboardLayout>
                <div className="gb-head">
                    <h2>Today's Visitors</h2>
                    {/* <div className="gb-head__right">
                        <Button text="Sign In Visitor" />
                    </div> */}
                </div>

                <Card>
                    <div className={styles.filterContainer}>
                        <div className={styles.inputContainer}>
                            <TextInput 
                                flex={2} 
                                placeholder="Search by name, email"
                                value={visitorName}
                                onChange={(e) => setVisitorName(e.target.value)}
                            />
                            <SelectInput 
                                flex={1.3} 
                                placeholder="Select Tenant"
                                options={tenantOptions}
                                defaultValue={selectedTenant}
                                onChange={onTenantChange}
                            />
                        </div>
                        <div className={styles.buttonContainer}>
                            <CSVLink data={filteredData} headers={header} filename="Todays_Visitors.csv">
                                <Button text="Export CSV" backgroundColor="#e3e3e3" color="#363636" icon={<CiExport />} />
                            </CSVLink>
                        </div>
                    </div>
                    {!loading ?
                    <div className="mt-2">
                        <DataTable
                            columns={columns}
                            data={filteredData.filter((visitor) => {
                                if(visitorName === '') return visitor;
                                if(visitor.visitor_name.toLowerCase().includes(visitorName.toLowerCase())) return visitor; 
                            })}
                            onRowClicked={rowClick}
                            highlightOnHover
                            pagination
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[3, 5, 10, 15, 20]}
                            paginationComponentOptions={{
                                rowsPerPageText: "Records per page:",
                                rangeSeparatorText: "out of"
                            }}
                        />
                    </div> : ''}
                </Card>
            </DashboardLayout>

            {modalOpen && <VisitorDetailsModal selectedVisitor={selectedVisitor} isModalOpen={modalOpen} closeModal={closeModal} /> }                   
        </>
      )
}

export default TodaysVisitors