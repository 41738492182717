import { useState, useEffect } from 'react';
import useApi from 'hooks/useApi';
import CustomModal      from 'components/modal/modal';
import TextInput        from 'components/inputs/textInput/textInput';
import SelectInput      from 'components/inputs/selectInput/selectInput';
import Button           from 'components/buttons/button';
import styles           from './signInModal.module.scss';
import Skeleton from 'react-loading-skeleton';
import { useAuth } from "contexts/authContext";
import { getAllSiteTenants } from 'functions/useTenantFunctions';
import { showErrorToast, showSuccessToast } from 'functions/toasts';
import moment from 'moment';
import { checkIfEmpty, isValidEmail } from 'functions/functions';

const Body = ({
    signInForm, setSignInForm,
    form, setForm,
    loading, setLoading,
    visitorName, setVisitorName,
    tenants, setTenants,
    tenant, setTenant,
    employees,
    signInVisitor,
    onBlur,
    error, setError,
    errorMessages, setErrorMessages,
    selectedEmployee,
    onTenantChange,
    onEmployeeSelect
}) => {

    console.log(form)

    const setFieldValue = (e, index) => {
        const updatedForm = signInForm.fields.map((field, i) => {
            if(i === index) {
                if(field.type !== 'dropdown') {
                    return {
                        ...field,
                        required : field.required === 'true' ? 'true' : 'false',
                        value : e.target.value
                    }
                } else {
                    console.log(e);
                    return {
                        ...field,
                        required : field.required === 'true' ? 'true' : 'false',
                        value : e.value
                    }
                }
                
            }
            return field;
        });
        console.log(updatedForm)
        setSignInForm(form => ({
            ...form,
            fields : updatedForm
        }));
       
    }



    const onVisitorBlur = (e) => {
        let visitorName = e.target.value;

        let errorObj = {
            ...error,
            visitorName : false
        }

        let errorMessageObj = {
            ...errorMessages,
            visitorName : ''
        }

        if(checkIfEmpty(visitorName)) {
            errorObj.visitorName = true;
            errorMessageObj.visitorName = 'Visitor Name is mandatory'
        }

        console.log(errorObj);

        setError(errorObj);
        setErrorMessages(errorMessageObj);
    }

    

    return (
        <>
        {!loading ?
            <div className={styles.signInForm}>
                <TextInput 
                    label="Visitor Name" 
                    placeholder="Enter Visitor Name" 
                    value={visitorName} 
                    onChange={(e) => setVisitorName(e.target.value)} 
                    required
                    onBlur={onVisitorBlur}
                    isError={error.visitorName}
                    errorMessage={errorMessages.visitorName} 
                />
                <SelectInput showError={true} isError={error.tenant} errorMessage={errorMessages.tenant} options={tenants} defaultValue={tenant} onChange={onTenantChange} label="Company Visiting" placeholder="Select Company" required />
                {signInForm?.employees?.enabled === 'true' && employees.length !== 0 && <SelectInput showError={true} isError={error.personVisiting} errorMessage={errorMessages.personVisiting} required={signInForm.employees.required === 'true'}  options={employees} defaultValue={selectedEmployee} onChange={onEmployeeSelect} label={signInForm.employees.label} placeholder={signInForm.employees.label} />}
                {signInForm && signInForm?.fields?.map((field, index) => {
                    console.log(field)
                    return (
                        field.type === 'string' || field.type === 'email' || field.type === 'numeric' ? 
                        <TextInput 
                            label={field.name} 
                            placeholder={field.label}
                            required={field.required === 'true' ? true : false}
                            type={field.type === 'numeric' ? 'number' : 'text'}
                            value={field.value}
                            onChange={(e) => setFieldValue(e, index)}
                            onBlur={() => onBlur(field)}
                            isError={error[field.name]}
                            errorMessage={errorMessages[field.name]} 
                        /> : field.type === 'dropdown' ? 
                        <SelectInput 
                            label={field.label} 
                            showError={true}
                            
                            menuPlacement="bottom"
                            defaultValue={field.value ? {label : field.value, value : field.value} : ''}
                            isError={error[field.name]}
                            errorMessage={errorMessages[field.name]}  
                            onChange={(e) => setFieldValue(e, index)} 
                            options={field.dropdownOptions} 
                            required={field.required === 'true'} 
                        /> : ''
                    )
                })}
            </div>
            : 
                <div>
                    <div>
                        <Skeleton width="20%" />
                        <Skeleton height={40} />
                    </div>
                    <div className="mt-2">
                        <Skeleton width="20%" />
                        <Skeleton height={40} />
                    </div>
                    <div className="mt-2">
                        <Skeleton width="20%" />
                        <Skeleton height={40} />
                    </div>
                </div>
            }
        </>
    )
}

const Footer = ({signInVisitor}) => {
    return (
        <div className={styles.footerContainer}>
            <Button text="Sign In Visitor" onClick={signInVisitor} />
        </div>
    )
}

/**
 * 
 * @param {Boolean} isModalOpen                 - Shows or hides modal
 * @param {Function} closeModal                 - Function to close the modal
 * @param {Function} getCurrentVisitorsData     - Function to load the current visitors data
 */

const SignInModal = ({isModalOpen, closeModal, getCurrentVisitorsData, selectedPreRegUser}) => {

    console.log(selectedPreRegUser)

    const [signInForm, setSignInForm]               = useState([]);
    const [form, setForm]                           = useState([]);
    const [loading, setLoading]                     = useState(false);
    const [visitorName, setVisitorName]             = useState('');
    const [tenant, setTenant]                       = useState('');
    const [selectedEmployee, setSelectedEmployee]   = useState('');
    const [tenants, setTenants]                     = useState([]);
    const [employees, setEmployees]                 = useState([]);
    const [error, setError]                         = useState([]);
    const [errorMessages, setErrorMessages]         = useState([]);

    const {user, currentBuilding} = useAuth();

    const {get, post} = useApi();

    useEffect(() => {
        fetchData();
    }, []);


    // Fetch All Data Once the Modal is Loaded
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await get(`buildings/${currentBuilding.attributes.building_id}/tenants`);
            const data = response.data.data;

            let tenantOptions = [];
            console.log(data);
            data.forEach(tenant => {
                if(tenant.attributes.is_active === 1) {
                    tenantOptions.push({
                        ...tenant.attributes,
                        label : tenant.attributes.name,
                        value : tenant.attributes.tenant_id,
                    })
                }
            });

            setTenants(tenantOptions);

            if(selectedPreRegUser) {
                console.log(tenantOptions);
                console.log(selectedPreRegUser)
                setVisitorName(selectedPreRegUser.visitor_name);

                let selectedTenant = tenantOptions.filter((tenant) => tenant.tenant_id === selectedPreRegUser.tenant_id);

                if(selectedPreRegUser.tenant_id) {
                    let employeeOptions = await fetchTenantEmployees(selectedPreRegUser.tenant_id);
                    let selectedEmployee = employeeOptions.filter((employee) => employee.employee_id === selectedPreRegUser.employee_id);
                    setSelectedEmployee(selectedEmployee[0]);
                }
                
                setTenant(selectedTenant[0]);
                setForm(selectedPreRegUser.form_data);
                setSignInForm(selectedPreRegUser.form_data);
            }

            setLoading(false);

        } catch(err) {
            showErrorToast('Something Went Wrong');
        }
    }

    // When tenant is selected from the dropdown
    const onTenantChange = async (e) => {
        setLoading(true)
        console.log(e);
        setTenant(e);
        await Promise.all([getTenantTemplate(e.template_id), fetchTenantEmployees(e.tenant_id)])
        setLoading(false);
    }

    // When Employee is Selected
    const onEmployeeSelect = (e) => {
        setSelectedEmployee(e);
    }

    // Fetch Template
    const getTenantTemplate = async(templateId) => {
        try {
            const res = await get(`form-templates/${templateId}`);
            let formObj = [];
            console.log(res.data.data)
            res.data.data?.form?.fields?.forEach((field) => {
                let obj = {
                    ...field,
                    value : '',
                };

                if(field.type === 'dropdown') {
                    let array = [];
                    field.dropdownOptions.forEach((item) => {
                        array.push({
                            label : item,
                            value : item
                        })
                    });

                    obj['dropdownOptions'] = array;
                }
                formObj.push(obj);
            })

            console.log(res.data.data);
            setForm(res.data.data.form);
            setSignInForm({
                ...res.data.data.form,
                fields : formObj
            })
            console.log(res);
            
        } catch(err) {
            console.error(err)
            showErrorToast('Something Went Wromg');
        }
    }

    // Fetch Tenant Employees
    const fetchTenantEmployees = async (tenantId) => {
        try {
            const response = await get(`tenants/${tenantId}/employees`);
            const data = response.data.data;

            let employeeOptions = [];

            console.log(data);

            data.forEach((employee) => {
                if(employee.attributes.is_active === 1) {
                    employeeOptions.push({
                        ...employee.attributes,
                        label : employee.attributes.name,
                        value : employee.attributes.employee_id
                    })
                }
                
            })

            setEmployees(employeeOptions)

            return employeeOptions;

        } catch(err) {
            console.error(err)
            showErrorToast('Something Went Wrong')
        }
    }

    // Function to sign in thr vistor
    const signInVisitor = async () => {
        setLoading(true);
        const now = moment();

        // console.log(tenant);

        let errorObj = {
            visitorName : false,
            personVisiting : false
        };

        console.log(tenant);
        console.log(errorObj)

        let errorMessageObj = {
            visitorName : '',
            personVisiting : ''
        }

        if(checkIfEmpty(visitorName)) {
            errorObj.visitorName = true;
            errorMessageObj.visitorName = 'Visitor Name is mandatory'
        }

        console.log(errorObj);

        if(!tenant) {
            errorObj.tenant = true;
            errorMessageObj.tenant = 'This field is mandatory'
        }

        console.log(form);

        if(form?.employees?.enabled === 'true' && form?.employees?.required === 'true' && employees.length > 0) {
            if(!selectedEmployee) {
                errorObj.personVisiting = true;
                errorMessageObj.personVisiting = 'This field is mandatory'
            }
        }

        signInForm?.fields?.forEach((field) => {
            console.log(field)
            errorObj = {
                ...errorObj,
                [field.name] : false
            }
    
            errorMessageObj = {
                ...errorMessageObj,
                [field.name] : ''
            }
    
            if(field.required === "true") {
                if(checkIfEmpty(field.value)) {
                    errorObj[field.name] = true;
                    errorMessageObj[field.name] = 'This field is mandatory'
                }
            }
            
    
            if(!errorObj[field.name] && field.type === 'email' && (field.value != "" && field.value != null)) {
                if(!isValidEmail(field.value)) {
                    errorObj[field.name] = true;
                    errorMessageObj[field.name] = 'Enter Valid Email Address'
                }
            }
        })

        console.log(errorObj);

        const hasError = Object.values(errorObj).some(value => value === true);
        console.log('Error',hasError)
        

        if(!hasError) {
            let data = {
                visitor_name : visitorName,
                tenant_id : tenant.tenant_id,
                form_data : JSON.stringify(signInForm),
                sign_in_time : now.format('Y-MM-DD HH:mm:ss'),
                is_pre_registered : selectedPreRegUser ? 1 : 0,
                pre_registration_id : selectedPreRegUser.pre_registration_id,
                manual_sign_in : 1
            };

            if(selectedEmployee) {
                data['employee_id'] = selectedEmployee.employee_id;
            }

            console.log(data);
    
            try {
                await post(`buildings/${currentBuilding.attributes.building_id}/sign-in`, data);
                getCurrentVisitorsData();
                showSuccessToast('New Visitor Signed In Successfully');
                setLoading(false)
                closeModal();
                
            } catch(err) {
                showErrorToast('Something Went Wrong');
                console.log(err);
            }
        }
            setError(errorObj);
            setErrorMessages(errorMessageObj);
            setLoading(false);
        
    }

    const onBlur = (field) => {
        console.log(field)
        let errorObj = {
            ...error,
            [field.name] : false
        }

        let errorMessageObj = {
            ...errorMessages,
            [field.name] : ''
        }

        if(field.required === "true") {
            if(checkIfEmpty(field.value)) {
                errorObj[field.name] = true;
                errorMessageObj[field.name] = 'This field is mandatory'
            }
        }
        

        if(!errorObj[field.name] && field.type === 'email') {
            if(!isValidEmail(field.value)) {
                errorObj[field.name] = true;
                errorMessageObj[field.name] = 'Enter Valid Email Address'
            }
        }

        console.log(errorObj)

        setError(errorObj);
        setErrorMessages(errorMessageObj)
    }

    return (
        <CustomModal isModalOpen={isModalOpen} closeModal={closeModal} width="40%" minHeight="60%">
            {{ 
                header : 'Sign In Visitor',
                body :  <Body 
                            signInForm={signInForm} 
                            setSignInForm={setSignInForm}
                            visitorName={visitorName}
                            setVisitorName={setVisitorName}
                            tenant={tenant}
                            setTenant={setTenant}
                            tenants={tenants}
                            setTenants={setTenants}
                            employees={employees}
                            onTenantChange={onTenantChange}
                            signInVisitor={signInVisitor}
                            form={form} setForm={form}
                            onBlur={onBlur}
                            error={error} setError={setError}
                            errorMessages={errorMessages} setErrorMessages={setErrorMessages}
                            selectedEmployee={selectedEmployee}
                            onEmployeeSelect={onEmployeeSelect}
                            loading={loading}
                            setLoading={setLoading}
                        />,
                footer : <Footer 
                            signInVisitor={signInVisitor}
                        />
             }}
        </CustomModal>
    )
}

export default SignInModal