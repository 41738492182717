import { useState, useRef, useEffect } from 'react';
import DashboardLayout      from 'layouts/dashboardLayout/dashboard.jsx';
import { CSVLink } from "react-csv";
import { CiExport } from "react-icons/ci";
import { BiExport } from "react-icons/bi";
import Card from 'components/card/card';
import styles from './deliveryReports.module.scss';
import DataTable            from 'react-data-table-component';
import SelectInput from 'components/inputs/selectInput/selectInput';
import Button from 'components/buttons/button';
import useDeliveryReports from './useDeliveryReports';
import DateRange from 'components/inputs/dateRangePicker/dateRangePicker';
import Row from 'layouts/row/row';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { dateTimeFormat } from 'functions/functions';
import TableSkeleton from 'layouts/skeletons/tableSkeleton/tableSkeleton';
import TextInput from 'components/inputs/textInput/textInput';

const DeliveryReportsScreen = () => {

    const {
        loading, setLoading,
        dataLoading, setDataLoading,
        filterLoading, setFilterLoading,
        data, setData,
        tenants, setTenants,
        selectedTenant, setSelectedTenant,
        sites, setSites,
        selectedSite, setSelectedSite,
        filteredData, setFilteredData,
        columns,
        applyFilters,
        onSiteChange,
        state, setState,
        visitorName, setVisitorName,
        header
    } = useDeliveryReports();
    

    
    const [showDatePicker, setShowDatePicker] = useState(false);

    const datePickerRef = useRef(null);
    const dateRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target) && dateRef.current && !dateRef.current.contains(event.target)) {
              // Clicked outside of the div, so hide the div
              setShowDatePicker(false);
            }
          }
      
          document.addEventListener('mousedown', handleClickOutside);
          return () => {
            document.removeEventListener('mousedown', handleClickOutside);
          };
    }, []);

    const onDateClick = () => {
        if(showDatePicker) {
            setShowDatePicker(false)
        } else {
            setShowDatePicker(true)
        }
    }

    const onDateSelect = (item) => {
        setState([item.selection])
        console.log(typeof state[0].startDate)
    }

  return (
    <>
        <DashboardLayout>
            <div className="gb-head">
                <h2>Delivery Reports</h2>
            </div>
            <div className={styles.reportsContainer}>
                <div className={styles.reportsContainer__filter}>
                    <Card>
                        <div className={styles.reportsContainer__filter__content}>
                            <h3>Apply Filters</h3>
                            {!loading && !filterLoading ?
                            <div className={styles.reportsContainer__filter__content__inputs}>
                                <SelectInput label="Site" options={sites} defaultValue={selectedSite} onChange={(e) => { setSelectedSite(e); onSiteChange(e) }} />
                                <SelectInput label="Tenant" options={tenants} defaultValue={selectedTenant} onChange={(e) => setSelectedTenant(e)} />
                                <div>
                                    <p>Arrival Date</p>
                                    <div className={styles.dates} onClick={onDateClick} ref={dateRef}>
                                        <div className={styles.date}>
                                            <p>{dateTimeFormat(state[0]?.startDate, "DD/MM/YYYY")}</p>
                                        </div>
                                        <p>-</p>
                                        <div className={styles.date}>
                                            <p>{dateTimeFormat(state[0]?.endDate, "DD/MM/YYYY")}</p>
                                        </div>
                                    </div>
                                    {showDatePicker && <div className={styles.dateRange} ref={datePickerRef}>
                                        <DateRange state={state} onChange={(item) => onDateSelect(item)} />
                                    </div> }
                                </div>
                                
                            </div> : '' }
                            <Row classNames="mt-4">
                                <Button text="Apply Filters" flex="1" onClick={applyFilters} />
                            </Row>
                        </div>
                    </Card>
                </div>
                <div className={styles.reportsContainer__data}>
                    <Card>
                        <div className={styles.reportsContainer__data__header}>
                            <div className={styles.reportsContainer__data__header__input}>
                                <TextInput placeholder="Search by visitor name" value={visitorName} onChange={(e) => setVisitorName(e.target.value)} />
                            </div>
                            <CSVLink data={filteredData} headers={header} filename="Deliveires_Report.csv">
                                <Button text="Export CSV" backgroundColor="#e3e3e3" color="#363636" icon={<CiExport />} />
                            </CSVLink>
                        </div>
                        <div className={styles.reportsContainer__data__table}>
                        {!loading && !dataLoading ?
                            <DataTable
                                columns={columns}
                                data={filteredData.filter((visitor) => {
                                    if(visitorName === '') return visitor;
                                    if(visitor.visitor_name.toLowerCase().includes(visitorName.toLowerCase())) return visitor; 
                                })}
                                // onRowClicked={rowClick}
                                highlightOnHover
                                pagination
                                paginationPerPage={10}
                                paginationRowsPerPageOptions={[3, 5, 10, 15, 20]}
                                paginationComponentOptions={{
                                    rowsPerPageText: "Records per page:",
                                    rangeSeparatorText: "out of"
                                }}
                            /> : <TableSkeleton /> } 
                        </div> 
                    </Card>
                </div>

            </div>
        </DashboardLayout>
    </>
  )
}

export default DeliveryReportsScreen