import DashboardLayout      from "layouts/dashboardLayout/dashboard.jsx";
import DataTable            from 'react-data-table-component';
import Button               from "components/buttons/button";
import Card                 from "components/card/card";
import CheckPermission      from "components/checkPermission/checkPermission";
import TextInput            from "components/inputs/textInput/textInput";
import SelectInput          from "components/inputs/selectInput/selectInput";
import RSwitch              from "components/switch/switch";
import AddNewUserModal      from "components/modal/addNewUserModal/addNewUserModal";
import TableSkeleton from "layouts/skeletons/tableSkeleton/tableSkeleton";
import Row                  from "layouts/row/row";
import useUsers from "./useUsers";
import styles               from "./users.module.scss";
import { useState } from "react";

const UsersScreen = () => {
    

    const {
        users, setUsers,
        userRoles,
        selectedRole, setSelectedRole,
        onUserTypeChange,
        filteredUsers, setFilteredUsers,
        searchByKey, setSearchByKey,
        columns,
        onRowClick,
        addNewUserModalStatus, setAddNewUserModalStatus,
        selectedUser, mode,
        closeAddNewUserModal,
        loading
    } = useUsers();

    

  return (
    <>
    <DashboardLayout>
        <div className="gb-head">
            <h2>Users</h2>
            <div className="gb-head__right">
                <CheckPermission permission="portal_users_invite">
                    <Button text="+ Add New User" onClick={() => setAddNewUserModalStatus(true)} />
                </CheckPermission>
            </div>
        </div>

        <Card>
            {!loading ?
            <>
            <Row justify="space-between" align="center" classNames={styles.filterContainer}>
                <Row gap="1rem" width="50%" classNames={styles.left}>
                    <TextInput placeholder="Search by name/email" showError={false} value={searchByKey} onChange={(e) => setSearchByKey(e.target.value)} />
                    <SelectInput placeholder="Select User Type" options={userRoles} defaultValue={selectedRole} onChange={onUserTypeChange} />
                </Row>
                {/* <div className={styles.invitedUsersContainer}>
                    <p>View Invited Users</p>
                </div> */}
            </Row>
            <div className="mt-3">
                <DataTable
                    columns={columns}
                    data={filteredUsers.filter(user => {
                        if(searchByKey === '') return user;
                        if(user.name.toLowerCase().includes(searchByKey.toLowerCase()) || user.email.toLowerCase().includes(searchByKey.toLowerCase())) return user;
                    })}
                    highlightOnHover
                    pagination
                    onRowClicked={onRowClick}
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[3, 5, 10, 15, 20]}
                    paginationComponentOptions={{
                        rowsPerPageText: "Records per page:",
                        rangeSeparatorText: "out of"
                    }}
                />
            </div>
            </> : <TableSkeleton classNames="mt-3" /> }
        </Card>

    </DashboardLayout>
    {addNewUserModalStatus && <AddNewUserModal isModalOpen={addNewUserModalStatus} closeModal={closeAddNewUserModal} mode={mode} selectedUser={selectedUser} /> }
    </>
  )
}

export default UsersScreen