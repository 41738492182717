import { useState, useEffect, useRef }  from 'react';
import useApi                   from 'hooks/useApi';
import RDatePicker              from 'components/inputs/datepicker/datepicker';
import CustomModal              from 'components/modal/modal';
import TextInput                from 'components/inputs/textInput/textInput';
import SelectInput              from 'components/inputs/selectInput/selectInput';
import BulkPreRegVisitorModal   from '../bulkPreRegVisitorModal/bulkPreRegVisitorModal';
import PreRegisterVisitorTemplate from 'assets/pre_registration_template.csv';
import Button                   from 'components/buttons/button';
import Skeleton from 'react-loading-skeleton';
import Papa from 'papaparse';
import Row                      from 'layouts/row/row';
import TimePick                 from 'components/inputs/timePick/timePick';
import { useAuth } from 'contexts/authContext';
import { capitalizeWords, checkIfEmpty, isValidEmail } from 'functions/functions';
import moment from 'moment';
import { showErrorToast, showSuccessToast } from 'functions/toasts';
import styles                   from './preRegisterModal.module.scss';
import Checkbox from 'components/inputs/checkbox/checkbox';



const Body = ({
    signInForm, setSignInForm,
    form, setForm,
    loading, setLoading,
    visitorName, setVisitorName,
    tenants, setTenants,
    tenant, setTenant,
    date, setDate,
    visitTime, setVisitTime,
    closeCurrentModal,
    onTenantChange,
    sendEmail, setSendEmail,
    invitationEmail, setInvitationEmail,
    employees, selectedEmployee, onEmployeeSelect,
    error, setError,
    errorMessages, setErrorMessages
}) => {

    const [openBulkImportModal, setOpenBulkImportModal] = useState(false);
    const [importedData, setImportedData]   = useState([]);
    const [file, setFile] = useState('');

    const inputFile = useRef();

    const setFieldValue = (e, index) => {
        const updatedForm = signInForm.fields.map((field, i) => {
            if(i === index) {
                // return {
                //     ...field,
                //     required : field.required === 'true' ? 'true' : 'false',
                //     required : false,
                //     value : e.target.value
                // }
                if(field.type !== 'dropdown') {
                    return {
                        ...field,
                        required : 'false',
                        value : e.target.value
                    }
                } else {
                    console.log(e);
                    return {
                        ...field,
                        required : 'false',
                        value : e.value
                    }
                }
            }
            return field;
        });

        setSignInForm(form => ({
            ...form,
            fields : updatedForm
        }));
    }

    const closeBulkImportModal = () => {
        setOpenBulkImportModal(false)
    }

    

    const checkEmpty = (e, field) => {
        console.log(e);
        let value = e.target.value;

        let errorObj = {
            ...error,
            [field] : false
        }

        let errorMessageObj = {
            ...errorMessages,
            [field] : ''
        }

        if(checkIfEmpty(value)) {
            errorObj[field] = true;
            errorMessageObj[field] = `${capitalizeWords(field)} is mandatory`
        }

        setError(errorObj);
        setErrorMessages(errorMessageObj);
    }

    const checkEmail = (e, field) => {
        let value = e.target.value;

        let errorObj = {
            ...error,
            [field] : false
        }

        let errorMessageObj = {
            ...errorMessages,
            [field] : ''
        }

        if(!isValidEmail(value)) {
            errorObj[field] = true;
            errorMessageObj[field] = `Enter Valid Email Address`
        }

        setError(errorObj);
        setErrorMessages(errorMessageObj);
    }

    const onBlur = (field) => {
        console.log(field)
        let errorObj = {
            ...error,
            [field.name] : false
        }

        let errorMessageObj = {
            ...errorMessages,
            [field.name] : ''
        }

        // if(field.required === "true") {
        //     if(checkIfEmpty(field.value)) {
        //         errorObj[field.name] = true;
        //         errorMessageObj[field.name] = 'This field is mandatory'
        //     }
        // }
        

        if(!errorObj[field.name] && field.type === 'email') {
            if(!isValidEmail(field.value)) {
                errorObj[field.name] = true;
                errorMessageObj[field.name] = 'Enter Valid Email Address'
            }
        }

        console.log(errorObj)

        setError(errorObj);
        setErrorMessages(errorMessageObj)
    }

    const handleFileUpload = (e) => {
        console.log('Changed')
        const file = e.target.files[0];
        Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
            // setData(results.data);
            console.log(results);
            setOpenBulkImportModal(true);
            setImportedData(results.data);
            setFile(file);
            inputFile.current.value = null;
        },
        });
    };

    const invitationEmailClick = () => {
        setSendEmail(!sendEmail);

        // Scroll to the field
        const fieldElement = document.getElementById('invitationEmailField'); // Replace 'fieldId' with the actual ID of your field
        if (fieldElement) {
            //fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            window.scrollTo(0, fieldElement.offsetTop - document.getElementsByClassName('modal_modalBody__Bls6Y')[0].offsetHeight);
        }
    }

    const onFileUploadClick = () => {
        console.log('open file');
        inputFile.current.click();
    }
    
    return (
        
        <>
            {!loading ?
            <>
                <div className={styles.bulkImportContainer}>
                    <p>Do you want to pre-register visitors in bulk ? <a href={PreRegisterVisitorTemplate} download='PreRegisterVisitorTemplate'>Download the template</a> and upload the file to import data.</p>
                    <div className={styles.btn} onClick={onFileUploadClick}>
                        <p>Bulk Import</p>
                    </div>
                    <input type="file" ref={inputFile} accept=".csv" hidden onChange={handleFileUpload} />
                </div>
                <div className={styles.signInForm}>
                    <TextInput 
                        label="Visitor Name" 
                        placeholder="Enter Visitor Name"
                        value={visitorName} 
                        onChange={(e) => setVisitorName(e.target.value)}
                        onBlur={(e) => checkEmpty(e, 'visitor name')}
                        isError={error['visitor name']}
                        errorMessage={errorMessages['visitor name']}
                        required 
                    />
                    <Row gap="1rem">
                        {/* <SelectInput showError={true} label="Visit Date" placeholder="Select Visit Date" /> */}
                        <RDatePicker 
                            label="Visit Date" 
                            value={date}
                            required={true}
                            onChange={(e) => setDate(e.target.value)}
                            onBlur={(e) => checkEmpty(e, 'date')}
                            isError={error.date}
                            errorMessage={errorMessages.date} 
                        />
                        <TimePick 
                            label="Expected Visit Time" 
                            value={visitTime}
                            required={true} 
                            onChange={(e) => setVisitTime(e.target.value)}
                            onBlur={(e) => checkEmpty(e, 'time')}
                            isError={error.time}
                            errorMessage={errorMessages.time}  
                        />
                    </Row>
                    <SelectInput 
                        showError={true} 
                        label="Company Visiting" 
                        placeholder="Select Company" 
                        required
                        options={tenants}
                        isError={error.tenant}
                        errorMessage={errorMessages.tenant}
                        defaultValue={tenant}
                        onChange={onTenantChange} 
                    />
                    {form?.employees?.enabled === 'true' && employees.length !== 0 && <SelectInput showError={true} options={employees} required={false} defaultValue={selectedEmployee} onChange={onEmployeeSelect} label={form.employees.label} placeholder={form.employees.label} />}
                    {signInForm && signInForm?.fields?.map((field, index) => {
                        return (
                            field.type === 'string' || field.type === 'email' || field.type === 'numeric' ? 
                            <TextInput 
                                label={field.name} 
                                placeholder={field.label}
                                required={false}
                                type={field.type === 'numeric' ? 'number' : 'text'}
                                value={field.value}
                                onBlur={() => onBlur(field)}
                                onChange={(e) => setFieldValue(e, index)}
                                isError={error[field.name]}
                                errorMessage={errorMessages[field.name]}  
                            /> : field.type === 'dropdown' ? 
                            <SelectInput 
                                label={field.label} 
                                showError={true}
                                menuPlacement="auto"
                                isError={error[field.name]}
                                errorMessage={errorMessages[field.name]}  
                                onChange={(e) => setFieldValue(e, index)} 
                                options={field.dropdownOptions} 
                                required={false} 
                            /> : ''
                            
                        )
                    })}
                    <div>
                        <Checkbox classNames="mt-2" label="Send Invitation Email to Visitor" checked={sendEmail} onChange={invitationEmailClick} />
                    </div>
                    {sendEmail && 
                        <div id="invitationEmailField">
                        <TextInput 
                            placeholder="Enter Invitation Email Address" 
                            onBlur={(e) => checkEmail(e, 'invitation email')} 
                            value={invitationEmail}
                            isError={error['invitation email']}
                            errorMessage={errorMessages['invitation email']} 
                            onChange={(e) => setInvitationEmail(e.target.value)} 
                        />
                        </div> 
                    }
                </div>
            </>
            : 
                <div>
                    <div>
                        <Skeleton width="20%" />
                        <Skeleton height={40} />
                    </div>
                    <div className="mt-2">
                        <Skeleton width="20%" />
                        <Skeleton height={40} />
                    </div>
                    <div className="mt-2">
                        <Skeleton width="20%" />
                        <Skeleton height={40} />
                    </div>
                </div>
            }
            {openBulkImportModal && <BulkPreRegVisitorModal isModalOpen={openBulkImportModal} closeModal={closeBulkImportModal} closeParentModal={closeCurrentModal} importedData={importedData} file={file} /> }
        </>
    )
}

const Footer = ({preRegisterVisitor, sendEmail}) => {
    return (
        <div className={styles.footerContainer}>
            <Button text={sendEmail ? 'Submit & Send Invitation' : 'Submit'} onClick={preRegisterVisitor} />
        </div>
    )
}

/**
 * 
 * @param {Boolean} isModalOpen     - Shows or hides modal
 * @param {Function} closeModal     - Function to close the modal 
 */

const PreRegisterModal = ({isModalOpen, closeModal}) => {
    const [loading, setLoading]             = useState(true);
    const [form, setForm]                   = useState([]);
    const [signInForm, setSignInForm]       = useState([]);
    const [visitorName, setVisitorName]     = useState('');
    const [date, setDate]                   = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [visitTime, setVisitTime]         = useState('12:00');
    const [tenants, setTenants]             = useState([]);
    const [employees, setEmployees]          = useState([]);
    const [selectedEmployee, setSelectedEmployee]   = useState('');
    const [tenant, setTenant]               = useState('');
    const [sendEmail, setSendEmail]         = useState(false);
    const [invitationEmail, setInvitationEmail] = useState('');
    const [error, setError]                 = useState('');
    const [errorMessages, setErrorMessages] = useState('');

    const {get, post} = useApi();
    const {currentBuilding} = useAuth();

    useEffect(() => {
        fetchData();
    }, []);


    // Fetch All Data Once the Modal is Loaded
    const fetchData = async () => {
        setLoading(true)
        try {
            const response = await get(`buildings/${currentBuilding.attributes.building_id}/tenants`);
            const data = response.data.data;

            let tenantOptions = [];
            data.forEach(tenant => {
                if(tenant.attributes.is_active === 1) {
                    tenantOptions.push({
                        ...tenant.attributes,
                        label : tenant.attributes.name,
                        value : tenant.attributes.tenant_id,
                    })
                }
            });

            setTenants(tenantOptions);
            setLoading(false)

        } catch(err) {
            showErrorToast('Something Went Wrong');
        }
    }


    // When tenant is selected from the dropdown
    const onTenantChange = async (e) => {
        setLoading(true)
        console.log(e);
        setTenant(e);
        await Promise.all([getTenantTemplate(e.template_id), fetchTenantEmployees(e.tenant_id)])
        setLoading(false);
    }

    // When Employee is Selected
    const onEmployeeSelect = (e) => {
        setSelectedEmployee(e);
    }

    // Fetch Template
    const getTenantTemplate = async(templateId) => {
        try {
            const res = await get(`form-templates/${templateId}`);
            let formObj = [];
            console.log(res.data.data)
            res.data.data?.form?.fields?.forEach((field) => {
                let obj = {
                    ...field,
                    value : '',
                };

                if(field.type === 'dropdown') {
                    let array = [];
                    field.dropdownOptions.forEach((item) => {
                        array.push({
                            label : item,
                            value : item
                        })
                    });

                    obj['dropdownOptions'] = array;
                }
                formObj.push(obj);
            })

            console.log(formObj);
            setForm(res.data.data.form);
            setSignInForm({
                ...res.data.data.form,
                fields : formObj
            })
            console.log(res);
            
        } catch(err) {
            console.error(err)
            showErrorToast('Something Went Wromg');
        }
    }

    // Fetch Tenant Employees
    const fetchTenantEmployees = async (tenantId) => {
        try {
            const response = await get(`tenants/${tenantId}/employees`);
            const data = response.data.data;

            let employeeOptions = [];

            data.forEach((employee) => {
                if(employee.attributes.is_active === 1) {
                    employeeOptions.push({
                        ...employee.attributes,
                        label : employee.attributes.name,
                        value : employee.attributes.employee_id
                    })
                }
            })

            setEmployees(employeeOptions)

        } catch(err) {
            console.error(err)
            showErrorToast('Something Went Wrong')
        }
    }

    // When tenant is selected from the dropdown
    // const onTenantChange = async (e) => {
    //     setLoading(true)
    //     let errorObj = {
    //         ...error,
    //         'tenant' : false
    //     }

    //     let errorMessageObj = {
    //         ...errorMessages,
    //         'tenant' : ''
    //     }

    //     console.log(errorObj)

    //     setError(errorObj);
    //     setErrorMessages(errorMessageObj)

    //     setTenant(e);
    //     try {
    //         const res = await get(`form-templates/${e.template_id}`);
    //         let formObj = [];
    //         res.data?.data?.form?.fields?.forEach((field) => {
    //             formObj.push({
    //                 ...field,
    //                 value : '',
    //             })
    //         })

    //         console.log(formObj);
    //         setSignInForm(formObj)
    //         console.log(res);
    //     } catch(err) {
    //         showErrorToast('Something Went Wromg');
    //     }

    //     setLoading(false)
    // }

    // Function to pre register new vistor
    const preRegisterVisitor = async () => {
        setLoading(true);
        const now = moment();

        console.log(tenant);

        let errorObj = {
            ...error,
            'visitor name' : false,
            'tenant' : false
        };

        let errorMessageObj = {
            ...errorMessages,
            'visitor name' : '',
            'tenant' : ''
        }

        console.log(visitorName, tenant, signInForm, date, visitTime);

        if(checkIfEmpty(visitorName)) {
            errorObj['visitor name'] = true;
            errorMessageObj['visitor name'] = 'Visitor Name is mandatory'
        }

        if(checkIfEmpty(date)) {
            errorObj['date'] = true;
            errorMessageObj['date'] = 'Please enter a valid date'
        }

        if(sendEmail) {
            if(checkIfEmpty(invitationEmail)) {
                errorObj['invitation email'] = true;
                errorMessageObj['invitation email'] = 'Please enter invitation email address'
            } else if(!isValidEmail(invitationEmail)) {
                errorObj['invitation email'] = true;
                errorMessageObj['invitation email'] = 'Enter Valid Email Address'
            }
        }

        if(!tenant) {
            errorObj['tenant'] = true;
            errorMessageObj['tenant'] = 'Please select a company'
        }

        signInForm?.fields?.forEach((field) => {
            errorObj = {
                ...errorObj,
                [field.name] : false
            }
    
            errorMessageObj = {
                ...errorMessageObj,
                [field.name] : ''
            }
    
            // if(field.required === "true") {
            //     if(checkIfEmpty(field.value)) {
            //         errorObj[field.name] = true;
            //         errorMessageObj[field.name] = 'This field is mandatory'
            //     }
            // }
            
    
            if(!errorObj[field.name] && field.type === 'email' && field.value != '') {
                if(!isValidEmail(field.value)) {
                    errorObj[field.name] = true;
                    errorMessageObj[field.name] = 'Enter Valid Email Address'
                }
            }
        })

        const hasError = Object.values(errorObj).some(value => value === true);

        if(!hasError) {
            let data = {
                visitor_name : visitorName,
                tenant_id : tenant.value,
                form_data : signInForm,
                arrival_date : date,
                arrival_time : visitTime,
                registered_admin_user : 1
            };

            if(selectedEmployee) {
                data['employee_id'] = selectedEmployee.employee_id;
            }

            if(sendEmail && invitationEmail) {
                data['invitation_email'] = invitationEmail;
            }

            console.log(data);
    
            try {
                await post(`buildings/${currentBuilding.attributes.building_id}/pre-register`, data);
                showSuccessToast('New Visitor Pre-Registered Successfully');
                setLoading(false)
                closeModal();
                
            } catch(err) {
                showErrorToast('Something Went Wrong');
                console.log(err);
            }
        } else {
            setError(errorObj);
            setErrorMessages(errorMessageObj);
            setLoading(false);
        }
        
    }


    
    return (
        <CustomModal isModalOpen={isModalOpen} closeModal={closeModal} width="40%" minHeight="70%">
            {{ 
                header : 'Pre-Register Visitor',
                body :  <Body 
                            signInForm={signInForm} 
                            setSignInForm={setSignInForm}
                            form={form} setForm={setForm}
                            visitorName={visitorName}
                            setVisitorName={setVisitorName}
                            closeCurrentModal={closeModal}
                            tenant={tenant}
                            setTenant={setTenant}
                            date={date}
                            setDate={setDate}
                            visitTime={visitTime}
                            setVisitTime={setVisitTime}
                            tenants={tenants}
                            sendEmail={sendEmail} setSendEmail={setSendEmail}
                            invitationEmail={invitationEmail} setInvitationEmail={setInvitationEmail}
                            employees={employees}
                            onEmployeeSelect={onEmployeeSelect}
                            selectedEmployee={selectedEmployee}
                            setTenants={setTenants}
                            onTenantChange={onTenantChange}
                            error={error} setError={setError}
                            errorMessages={errorMessages} setErrorMessages={setErrorMessages}
                            loading={loading}
                            setLoading={setLoading}
                        />,
                footer :    <Footer 
                                preRegisterVisitor={preRegisterVisitor}
                                sendEmail={sendEmail}
                            />
             }}
        </CustomModal>
    )
}

export default PreRegisterModal