import DashboardLayout      from "layouts/dashboardLayout/dashboard.jsx";
import DataTable            from 'react-data-table-component';
import Button               from "components/buttons/button";
import { CSVLink } from "react-csv";
import { CiExport } from "react-icons/ci";
import Card                 from "components/card/card";
import Skeleton             from "react-loading-skeleton";
import TextInput            from "components/inputs/textInput/textInput";
import SelectInput          from "components/inputs/selectInput/selectInput";
import SignInModal          from "components/modal/signInModal/signInModal";
import styles               from './preRegisteredVisitors.module.scss';
import usePreRegVisitors    from "./usePreRegVisitors";
import Row from "layouts/row/row";
import TableSkeleton from "layouts/skeletons/tableSkeleton/tableSkeleton";

const PreRegisteredVisitors = () => {

    const {
        modalOpen,
        setModalOpen,
        columns,
        data, filteredData,
        tenantOptions,
        rowClick,
        fetchData,
        closeModal,
        header,
        visitorName, setVisitorName,
        onTenantChange,
        selectedTenant,
        onPreRegVisitorClick,
        selectedPreRegUser, setSelectedPreRegUser,
        loading, setLoading,
    } = usePreRegVisitors();

  return (
    <>
    <DashboardLayout>
        <div className="gb-head">
            <h2>Pre-Registered Visitors</h2>
            {/* <div className="gb-head__right">
                <Button text="+ Pre-Register New Visitor" />
            </div> */}
        </div>

        <Card>
            <div className={styles.filterContainer}>
                <div className={styles.inputContainer}>
                    <TextInput 
                        flex={2} 
                        placeholder="Search by name"
                        value={visitorName}
                        onChange={(e) => setVisitorName(e.target.value)}
                    />
                    <SelectInput 
                        flex={1.3} 
                        placeholder="Select Tenant" 
                        options={tenantOptions}
                        defaultValue={selectedTenant}
                        onChange={onTenantChange} 
                    />
                </div>
                <div className={styles.buttonContainer}>
                    <CSVLink data={filteredData} headers={header} filename="Pre Registered Visitors.csv">
                        <Button text="Export CSV" backgroundColor="#e3e3e3" color="#363636" icon={<CiExport />} />
                    </CSVLink>
                </div>
            </div>
            <div className="mt-2">
                {!loading ? 
                <DataTable
                    columns={columns}
                    data={filteredData.filter((visitor) => {
                        if(visitorName === '') return visitor;
                        if(visitor.visitor_name.toLowerCase().includes(visitorName.toLowerCase())) return visitor; 
                    })}
                    onRowClicked={onPreRegVisitorClick}
                    highlightOnHover
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[3, 5, 10, 15, 20]}
                    paginationComponentOptions={{
                        rowsPerPageText: "Records per page:",
                        rangeSeparatorText: "out of"
                    }}
                /> : 
                    <TableSkeleton />
                }
            </div>
        </Card>
    </DashboardLayout>

    {modalOpen && <SignInModal isModalOpen={modalOpen} closeModal={closeModal} selectedPreRegUser={selectedPreRegUser} getCurrentVisitorsData={fetchData} /> }                  
</>
  )
}

export default PreRegisteredVisitors