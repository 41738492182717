// import { DateRange } from 'react-date-range';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const DateRange = ({state, onChange}) => {
    
    return (
        <DateRangePicker
            className='date-range'
            editableDateInputs={true}
            onChange={onChange}
            moveRangeOnFirstSelection={false}
            ranges={state}
            showDateDisplay={false}
            showPreview={true}
            retainEndDateOnFirstSelection={true}
            showMonthAndYearPickers={true}
            showSelectionPreview={true}
            rangeColors="#fcba03"
        />
    )
}

export default DateRange;